import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbSpinnerModule,
  NbThemeService,
  NbTooltipModule,
} from '@nebular/theme';
import {NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import { MkadIconCloseComponent} from '../../global';
import {LegalContentDialogComponent } from '../../main';
import {AboutUsService} from '@shared/services';
import {MkadBaseComponent} from '@shared/components';
import {AboutUsDataInterface} from '@shared/interface';
import {MkadThemeValueEnum} from '@shared/enums';

@Component({
  selector: 'mkad-about-us',
  templateUrl: './about-us.component.html',
  standalone: true,
  providers: [AboutUsService],
  imports: [
    TranslateModule,
    RouterLink,
    NbCardModule,
    NbIconModule,
    NbTooltipModule,
    NbSpinnerModule,
    NbButtonModule,
    MkadIconCloseComponent,
    LegalContentDialogComponent,
    NgOptimizedImage,
  ],
})
export class MkadAboutUsComponent extends MkadBaseComponent<MkadAboutUsComponent> implements OnInit, OnDestroy {
  private readonly aboutUsService = inject(AboutUsService);
  aboutUsData: AboutUsDataInterface | null = null;
  frontVersion = environment['version'];
  logoUrl = '';
  override toTranslate = {
    'app.footer.aboutUs': '',
  }

  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   * @param dialogRef NbDialogRef dependency injection
   * @param theme NbThemeService dependency injection
   */
  constructor(override translate: TranslateService,
              override dialogRef: NbDialogRef<MkadAboutUsComponent>,
              private readonly theme: NbThemeService) {
    super(translate, dialogRef);
    this.theme.onThemeChange().subscribe({
      next: (newTheme) => {
        this.setLogoUrl(newTheme.name);
      },
    });
  }

  /**
   * @description
   * Method that response to OnInit life cycle
   */
  override ngOnInit(): void {
    super.ngOnInit();
    this.getAboutUsData();
  }

  /**
   * @description
   * Method that get the about us data
   * @private
   */
  private getAboutUsData() {
    this.aboutUsService.getAboutUsData().subscribe({
      next: (response) => {
        if (response) {
          this.aboutUsData = {...response};
        } else{
          this.aboutUsData = {
            description: this.translate.instant('app.main.about.dataBurned.description'),
            email: 'info@cqinversiones.co',
            url: 'https://www.meerkad.net/index.php/es/',
            back_version: '',
            facebook: 'https://www.facebook.com/MeerKADIoT',
            linkedin: 'https://co.linkedin.com/company/cq-inversiones',
            instagram: 'https://www.facebook.com/MeerKADIoT',
            disclaimer: '',
            policy: ''
          };
        }
      },
    });
  }

  /**
   * @description
   * Method that response to OnInit life cycle
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * @description
   * Method that closes the modal
   */
  onClickClose(): void {
    this.dialogRef.close();
  }

  /**
   * @description
   * Method that open a component like a modal
   */
  onClickOpenTermsAndConditions(): void {
    this.openDialog(LegalContentDialogComponent, {
      context: {
        data: this.aboutUsData ? this.aboutUsData.disclaimer : null
      },
    });
  }

  /**
   * @description
   * Method that open a component like a modal
   */
  onClickOpenPolicy(): void {
    this.dialog.open(LegalContentDialogComponent, {
      context: {
        data: this.aboutUsData ? this.aboutUsData.policy : null
      },
    });
  }

  /**
   * @description
   * Method that return the logo url
   */
  setLogoUrl(theme: string) {
    this.logoUrl = `assets/image/meerkad_${theme !== MkadThemeValueEnum.corporate ? 'dark' : 'light'}.webp`;
  }

}
