<footer class="w-full h-full flex flex-row justify-content-between">
  <section class="flex flex-row align-items-center">
    <nb-icon status="basic" class="cursor-pointer text-2xl" [nbTooltip]="'app.main.about.tooltip' | translate" icon="circle-exclamation"
             (click)="clickLeftIcon.emit()"/>
  </section>

  <section class="flex flex-column align-items-center justify-content-center">
    <div class="font-semibold">
      {{ 'app.footer.copy' | translate }}
      <a [href]="politiesUrl"
         target="_blank" class="no-underline"
         [nbTooltip]="'app.footer.legalNotice.tooltip' | translate"
         [attr.aria-label]="'app.footer.legalNotice.aria' | translate">
        {{ 'app.footer.legalNotice.label' | translate }}
      </a>
    </div>
  </section>
    @if (customerData()?.customer_logo) {
      <img class="h-full w-auto" [src]="parseString(customerData()?.customer_logo)"
           [alt]="'app.header.logo.alt' | translate"
           height="60" width="180">
    } @else if (customerData()?.customer_name) {
      <section class="alert_inline_content h-min w-fit align-self-center py-2 font-bold">
        {{ parseString(customerData()?.customer_name) }}
      </section>
  } @else {
    <a [attr.href]="url" target="_blank" class="layout_footer_img">
      <img class="h-full w-auto" ngSrc="../../../../../assets/image/cq-logo.png"
           [alt]="'app.header.logo.alt' | translate"
           height="63"
           width="187">
    </a>
  }
</footer>
