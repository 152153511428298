import * as i1 from '@nebular/auth';
import { NbAuthResult, NbAuthService, NB_AUTH_STRATEGIES } from '@nebular/auth';
import * as i0 from '@angular/core';
import { Injectable, Inject, InjectionToken, inject, NgModule } from '@angular/core';
import { map, lastValueFrom, of } from 'rxjs';
import * as i2 from '@zibanu/lib';
import { ZbSecureStorageService } from '@zibanu/lib';
import * as i1$1 from '@ngx-translate/core';

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
/*
* Project:      Zibanu
* Developed by: Mario Cerón Charry
* Date:         13/03/24 - 12:01
* Module name:
* File name:    index.ts
* IDE:          WebStorm
*/

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
const ZB_AUTH_DEFAULT_OPTION = {
  urls: {
    permission: {
      list: 'auth/permission/list'
    },
    level: {
      list: 'auth/group-level/list'
    },
    group: {
      list: 'auth/group/list'
    },
    user: {
      list: 'auth/user/list',
      add: 'auth/user/add',
      delete: 'auth/user/delete',
      update: 'auth/user/update',
      retrieve: 'auth/user/retrieve',
      avatar: 'auth/user/avatar',
      profile: 'auth/user/profile',
      profileUpdate: 'auth/profile/update'
    }
  }
};

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
/*
 * Project:      zibanu
 * Developed by: Juan David Pelaez
 * Date:         25/05/24 - 07:04:17
 * Module name:  zb-strategy-name.const
 * File name:    zb-strategy-name.const.ts
 * IDE:          WebStorm
 */
/**
 * @description
 * Constant with the current strategy auth
 */
const ZB_STRATEGY_NAME = 'email';

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
/*
 * Project:      zibanu
 * Developed by: Juan David Pelaez
 * Date:         24/05/24 - 18:05:59
 * Module name:  index
 * File name:    index.ts
 * IDE:          WebStorm
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
/*
 * Project:      zibanu
 * Developed by: Juan David Pelaez Cumbe
 * Date:         29/01/24 - 19:14
 * Module name:  zb-auth-result.interface
 * File name:    zb-auth-result.interface.ts
 * IDE:          WebStorm
 */
/**
 * Facade class that overrides the response of certain auth zibanu methods
 */
class ZbAuthResult extends NbAuthResult {
  /**
   * Constructor class
   * @param success success entity dependency injection
   * @param response response entity dependency injection
   * @param redirect redirect entity dependency injection
   * @param errors errors entity dependency injection
   * @param messages message entity dependency injection
   * @param token token dependency injection
   */
  constructor(success, response, redirect, errors, messages, token) {
    super(success, response, redirect, errors, messages, token);
    /**
     * @description
     * Method that response the avatar in the user data
     */
    this.getAvatar = () => {
      return super.getResponse().body?.avatar ?? '';
    };
  }
  /**
   * @description
   * Method that return the response http
   * @overview
   */
  getResponse() {
    return super.getResponse();
  }
}

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         25/08/23 - 12:43
* Module name:  Zibanu Angular
* File name:    index.ts
* IDE:          WebStorm
*/

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         15/08/23 - 20:27
* Module name:  Zibanu Angular
* File name:    zb-auth.services.ts
* IDE:          WebStorm
*/
/**
 * Class to override NbAuthService and extend new methods.
 * @extends NbAuthService
 */
class ZbAuthService extends NbAuthService {
  /**
   * @description
   * Constructor class
   * @param tokenService NbTokenService injection dependency
   * @param httpClient ZbHttpClient injection dependency
   * @param strategies NbAuthStrategies injection dependency
   * @param zbAuthOptions ZbAuthOptions injection dependency
   */
  constructor(tokenService, httpClient, strategies, zbAuthOptions) {
    super(tokenService, strategies);
    this.tokenService = tokenService;
    this.httpClient = httpClient;
    this.strategies = strategies;
    this.zbAuthOptions = zbAuthOptions;
    this.defaultUrl = ZB_AUTH_DEFAULT_OPTION.urls;
  }
  /**
   * @description
   * Method that validates if the url is defined in the module options
   * @param value endpoint to validate
   * @param defaultUrl default endpoint
   */
  validOptionUrl(value, defaultUrl) {
    return value ?? defaultUrl;
  }
  /**
   * @description
   * Method that return the current strategy
   * @param strategyName
   */
  getCurrentStrategy(strategyName) {
    return super.getStrategy(strategyName);
  }
  /**
   * @description
   * Override method to authenticate users from backend
   * @param strategyName - Name of nebular library strategy authentication
   * @param data - Data required for authentication
   */
  authenticate(strategyName, data) {
    return super.authenticate(strategyName, data).pipe(map(response => {
      return new ZbAuthResult(response.isSuccess(), response.getResponse(), response.getRedirect(), response.getErrors(), response.getMessages(), response.getToken());
    }));
  }
  /**
   * @description
   * Override method to refresh JWT token.
   * @param strategyName - Name of nebular library strategy name
   * @param data - Data required for authentication
   */
  refreshToken(strategyName, data) {
    return super.refreshToken(strategyName, data);
  }
  /**
   * @description
   * Method that map the response of onTokenChange event to set the infoPayload
   */
  onTokenChange() {
    return super.onTokenChange();
  }
  /**
   * @description
   * Method to use the destroy user endpoint at backend
   * @param user_id - User id to destroy
   */
  async deleteUser(user_id) {
    return await this.httpClient.asyncPost(this.validOptionUrl(this.zbAuthOptions.urls.user?.delete, this.defaultUrl.user?.delete), {
      user_id
    });
  }
  /**
   * @description
   * Method to update user and user profile. The interfaces IZbUser must have either the id or the email to successfully
   * call the endpoint
   * @param zbUser - IZbUser with data to update
   */
  async updateUser(zbUser) {
    return await this.httpClient.asyncPost(this.validOptionUrl(this.zbAuthOptions.urls.user?.update, this.defaultUrl.user?.update), zbUser);
  }
  /**
   * @description
   * Method to add users to backend database. Require IZbUser for add it.
   * @param zbUser - IZbUser with data to add
   */
  async addUser(zbUser) {
    return await this.httpClient.asyncPost(this.validOptionUrl(this.zbAuthOptions.urls.user?.add, this.defaultUrl.user?.add), zbUser);
  }
  /**
   * @description
   * Method to toggle is_active flag from isActive parameter value
   * @param id - User id
   * @param isActive - Flag value to save
   */
  async toggleStatusUser(id, isActive) {
    const zbUser = {
      id,
      is_active: isActive ?? false
    };
    return await this.updateUser(zbUser);
  }
  /**
   * @description
   * Method to get active user list from backend.
   */
  getUsers() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.user?.list, this.defaultUrl.user?.list));
  }
  /**
   * @description
   *
   */
  retrieveUser() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.user?.retrieve, this.defaultUrl.user?.retrieve));
  }
  /**
   * @description
   * Method to get the data of the user
   */
  getUserProfileData() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.user?.profile, this.defaultUrl.user?.profile));
  }
  /**
   * @description
   * Method that returns the logged-in user's avatar
   */
  getUserAvatar() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.user?.avatar, this.defaultUrl.user?.avatar));
  }
  /**
   * @description
   * Method that updates the logged-in user's profile
   */
  updateUserProfile() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.user?.profileUpdate, this.defaultUrl.user?.profileUpdate));
  }
  /**
   * @description
   * Method to get from backend available roles list.
   */
  getGroups() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.group?.list, this.defaultUrl.group?.list));
  }
  /**
   * @description
   * Method to get from backend available levels list.
   */
  getLevels() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.level?.list, this.defaultUrl.level?.list));
  }
  /**
   * @description
   * Method to get from backend available permission list.
   */
  getPermissions() {
    return this.httpClient.post(this.validOptionUrl(this.zbAuthOptions.urls.permission?.list, this.defaultUrl.permission?.list));
  }
  static {
    this.ɵfac = function ZbAuthService_Factory(t) {
      return new (t || ZbAuthService)(i0.ɵɵinject(i1.NbTokenService), i0.ɵɵinject(i2.ZbHttpClient), i0.ɵɵinject(NB_AUTH_STRATEGIES), i0.ɵɵinject(ZbAuthConfigService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZbAuthService,
      factory: ZbAuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZbAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.NbTokenService
  }, {
    type: i2.ZbHttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NB_AUTH_STRATEGIES]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ZbAuthConfigService]
    }]
  }], null);
})();

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         25/08/23 - 9:47
* Module name:  Zibanu Angular
* File name:    zb-auth-config.services.ts
* IDE:          WebStorm
*/
/**
 * Injection Token config for zibanu-auth lib
 */
const ZbAuthConfigService = new InjectionToken('ZbAuthOptions');

/**
 * Class to check the level required to execute some action
 */
class ZbAuthCheckerService {
  /**
   * @description
   * Class constructor
   * @param zbAuthService ZbAuthService
   */
  constructor(zbAuthService) {
    this.zbAuthService = zbAuthService;
    this.userLevel = 6 /* UserLevelEnum.Guest */;
  }
  /**
   * @description
   * Method to get true if user level is minor or equal than level required.
   * @param levelToCheck : number: Level required to execute some action
   */
  isGranted(levelToCheck) {
    return this.zbAuthService.getToken().pipe(map(token => {
      const guestIsGranted = 6 /* UserLevelEnum.Guest */ <= levelToCheck;
      if (token.isValid()) {
        if (token.getPayload().user.level != undefined) {
          this.userLevel = token.getPayload().user.level;
          return this.userLevel <= levelToCheck;
        }
        return guestIsGranted;
      }
      return guestIsGranted;
    }));
  }
  static {
    this.ɵfac = function ZbAuthCheckerService_Factory(t) {
      return new (t || ZbAuthCheckerService)(i0.ɵɵinject(ZbAuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZbAuthCheckerService,
      factory: ZbAuthCheckerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZbAuthCheckerService, [{
    type: Injectable
  }], () => [{
    type: ZbAuthService
  }], null);
})();

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Juan David Cumbe
* Date:         30/10/23 - 11:04
* Module name:  Zibanu auth
* File name:    zb-auth-manager.services.ts
* IDE:          WebStorm
*/
/**
 * Facade services providing the simplified features of the zbAuth services
 */
class ZbAuthManagerService {
  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   */
  constructor(translate) {
    this.translate = translate;
    this.zbAuthService = inject(ZbAuthService);
    this.storage = inject(ZbSecureStorageService);
    this.strategyName = ZB_STRATEGY_NAME;
    this.strategy = this.zbAuthService.getCurrentStrategy(this.strategyName);
  }
  /**
   * @description
   * Method that call the getUserProfileData method for get the user data
   */
  getUserProfileData() {
    return this.zbAuthService.getUserProfileData();
  }
  /**
   * @description
   * Method that call the authenticate method for do 'login'
   * @param loginData credentials for login
   */
  async doLogin({
    password,
    mail,
    close
  }) {
    return lastValueFrom(this.zbAuthService.authenticate(this.strategyName, {
      email: mail,
      password,
      close
    }));
  }
  /**
   * @description
   * Method to check if the session is stored and force login
   */
  doStoredLogin() {
    const {
      data
    } = this.storage.getUserCredentials();
    if (!data) return of(null);
    return this.zbAuthService.authenticate(this.strategyName, {
      email: data.mail,
      password: data.access,
      close: true
    });
  }
  /**
   * @description
   * Method that call the requestPassword method for reset password
   * @param email Mail to reset the password
   */
  async doRequestPassword(email) {
    return lastValueFrom(this.zbAuthService.requestPassword(this.strategyName, {
      email
    }));
  }
  /**
   * @description
   * Method that call the resetPassword method for change password
   * @param currentPassword current user password
   * @param newPassword new user password
   */
  doResetPassword(currentPassword, newPassword) {
    return this.zbAuthService.resetPassword(this.strategyName, {
      old_password: currentPassword,
      new_password: newPassword
    });
  }
  /**
   * @description
   * Method that call the logout method for do 'logout'
   */
  doLogout() {
    this.storage.deleteCredentialsStored();
    return this.zbAuthService.logout(this.strategyName);
  }
  /**
   * @description
   * Method thar return the strategy name
   */
  getStrategyName() {
    if (!this.strategy) return ZB_STRATEGY_NAME;
    return this.strategy.getName();
  }
  /**
   * @description
   * Method that return the current token
   */
  getToken() {
    return this.zbAuthService.getToken();
  }
  /**
   * @description
   * Method that return the onTokenChange method from ZbAuthService services
   */
  onChangeToken() {
    return this.zbAuthService.onTokenChange();
  }
  /**
   * @description
   * Method that returns the call to the action of refreshing the token
   * @param data
   */
  refreshToken(data) {
    return this.zbAuthService.refreshToken(this.strategyName, data);
  }
  /**
   * @description
   * Method that return the call of the isAuthenticated method
   */
  isAuthenticated() {
    return this.zbAuthService.isAuthenticated();
  }
  /**
   * @description
   * Method that returns the list of levels
   */
  getLevelsList() {
    return this.zbAuthService.getLevels();
  }
  /**
   * @description
   * Method that returns the list of roles
   */
  getRolesList() {
    return this.zbAuthService.getGroups();
  }
  /**
   * @description
   * Method that returns the list of permissions
   */
  getPermissionsList() {
    return this.zbAuthService.getPermissions();
  }
  /**
   * @description
   * Method that calls the authService's getUsers method
   */
  usersList() {
    return this.zbAuthService.getUsers();
  }
  /**
   * @description
   * Method that calls the authService's addUser method
   */
  userAdd(user) {
    return this.zbAuthService.addUser(user);
  }
  /**
   * @description
   * Method that calls the authService's deleteUser method
   */
  userDelete(id) {
    return this.zbAuthService.deleteUser(id);
  }
  /**
   * @description
   * Method that calls the authService's updateUser method
   */
  userUpdate(user) {
    return this.zbAuthService.updateUser(user);
  }
  /**
   * @description
   * Method that calls the authService's retrieveUser method
   */
  userGet() {
    return this.zbAuthService.retrieveUser();
  }
  /**
   * @description
   * Method that calls the authService's retrieveUser method
   */
  userChangeStatus(id, active) {
    return this.zbAuthService.toggleStatusUser(id, active);
  }
  /**
   * @description
   * Method that calls the authService's getUserProfileData method
   */
  userProfile() {
    return this.zbAuthService.getUserProfileData();
  }
  /**
   * @description
   * Method that calls the authService's getUserAvatar method
   */
  userAvatar() {
    return this.zbAuthService.getUserAvatar();
  }
  /**
   * @description
   * Method that calls the authService's updateUserProfile method
   */
  userProfileUpdate() {
    return this.zbAuthService.updateUserProfile();
  }
  static {
    this.ɵfac = function ZbAuthManagerService_Factory(t) {
      return new (t || ZbAuthManagerService)(i0.ɵɵinject(i1$1.TranslateService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZbAuthManagerService,
      factory: ZbAuthManagerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZbAuthManagerService, [{
    type: Injectable
  }], () => [{
    type: i1$1.TranslateService
  }], null);
})();

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         15/08/23 - 20:30
* Module name:  Zibanu Angular
* File name:    index.ts
* IDE:          WebStorm
*/

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
class NgxZibanuAuthModule {
  static forRoot(zbAuthOptions = ZB_AUTH_DEFAULT_OPTION) {
    return {
      ngModule: NgxZibanuAuthModule,
      providers: [{
        provide: ZbAuthConfigService,
        useValue: zbAuthOptions
      }]
    };
  }
  static {
    this.ɵfac = function NgxZibanuAuthModule_Factory(t) {
      return new (t || NgxZibanuAuthModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxZibanuAuthModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxZibanuAuthModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: []
    }]
  }], null, null);
})();

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
 * Public API Surface of ngx-zibanu-auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxZibanuAuthModule, ZB_AUTH_DEFAULT_OPTION, ZB_STRATEGY_NAME, ZbAuthCheckerService, ZbAuthConfigService, ZbAuthManagerService, ZbAuthResult, ZbAuthService };
