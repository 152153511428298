/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         19/12/23 - 10:38 PM
 * Module name:  title-page.constants.ts
 * File name:    title-page.constants.ts
 * IDE:          WebStorm
 */

export const headerTitle = (pageName?: string): string => {
  return `MeerKAD - ${pageName ?? ' Always watching'}`;
};
