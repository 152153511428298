/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         8/02/24 - 9:48
 * Module name:  parse-file-to-base64.utils.ts
 * File name:    parse-file-to-base64.utils.ts
 * IDE:          WebStorm
 */


import {FILE_FORMAT_ALLOW, fileErrors, fileImageProportion, MAX_FILE_SIZE, MAX_FILE_WIDTH} from '../constants';

/**
 * Function to parse file to base64
 * @param file file to parse
 * @param proportion proportion to validate
 * @param minWidth minimum width to validate
 * @param maxFileSize maximum file size to validate in bytes
 * @constructor
 */
export const ParseFileToBase64 = (file: File, proportion: fileImageProportion = fileImageProportion.oneToOne, minWidth = MAX_FILE_WIDTH, maxFileSize = MAX_FILE_SIZE) => {
  const customMaxFileSize = maxFileSize;
  const customProportion = proportion;
  const customWidth = minWidth;
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    /** Valid image type **/
    if (!file.type.includes('image/')) {
      return reject(fileErrors.format);
    }

    /** Valid image format **/
    const extension = file.type.split('/')[1];
    if (!FILE_FORMAT_ALLOW.some(ex => ex.toLowerCase() === extension)) {
      return reject(fileErrors.format);
    }

    /** Valid image size in kilobytes **/
    if (file.size > customMaxFileSize) {
      return reject(fileErrors.maxSize);
    }

    /** process image **/
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (fileRendered) => {
      /** Create img tag on runtime **/
      const image = new Image();
      image.src = fileRendered.target?.result?.toString() ?? '';

      image.onload = () => {
        /** Valid type of proportion (1:1) **/
        if (customProportion === fileImageProportion.threeToOne) {
          /** Valid proportion (3:1) **/
          const horizontalProportionSize = image.naturalHeight * 3;
          if (image.naturalWidth > horizontalProportionSize) {
            return reject(fileErrors.proportion);
          }

          /** Valid image width **/
          if (image.naturalWidth > customWidth) {
            return reject(fileErrors.maxWidth);
          }

          /** Valid proportion (1:1) **/
        } else if (image.naturalWidth !== image.naturalHeight) {
          return reject(fileErrors.proportion);
        }
        resolve(reader.result);
      };
      image.onerror =(error) => reject(error)
    };
    reader.onerror = (error) => reject(error);
  });
};
