import {Component, inject, Input, OnDestroy, signal} from '@angular/core';
import {NbIconModule, NbTooltipModule, NbTrigger} from '@nebular/theme';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {FIVE_HUNDRED} from '@shared/services/settings/system/mkad-icons.store.';

@Component({
  selector: 'mkad-form-label',
  template: `
    <label [for]="controlId">
      {{ text }}
    </label>
    <sup class="required"
         [nbTooltip]="tooltip ?? ''"
         [nbTooltipTrigger]="tooltipTrigger()">
      <nb-icon
        class="text-base"
        icon="circle-exclamation"
        [status]="status"/>
    </sup>
  `,
  standalone: true,
  imports: [NbIconModule, NbTooltipModule],
})
export class MkadFormLabelComponent implements OnDestroy {
  @Input({required: true}) controlId?: string;
  @Input({required: true}) text?: string;
  @Input({required: false}) tooltip?: string;
  @Input({required: false}) status?: string;
  @Input({required: false}) isRequired: boolean = true;
  private readonly observerRef = inject(BreakpointObserver);
  private readonly unsubscribe$ = new Subject<void>();
  tooltipTrigger = signal<NbTrigger>(NbTrigger.HINT);

  constructor() {
    this.observerRef.observe('(max-width: 1007px) and (min-width: 2px)')
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(FIVE_HUNDRED),
      )
      .subscribe({
        next: (response: BreakpointState) => {
          this.tooltipTrigger.set(response.matches ? NbTrigger.CLICK : NbTrigger.HINT);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
