/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         22/12/23 - 12:07 AM
 * Module name:  mkad-status-device.service
 * File name:    status-device.service.ts
 * IDE:          WebStorm
 */
import {Injectable} from '@angular/core';
import {URLS_SERVICE} from '../url-service.const';
import {StatusDeviceInterface} from '../../interface';
import {EMPTY, map, Observable, switchMap} from 'rxjs';
import {MkadBaseService} from '../mkad-base.service';
import {StatusDeviceColorConst, StatusDeviceKeyParse, StatusDeviceTextConst} from '../../utils';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class StatusDeviceService extends MkadBaseService {
  readonly statusUrl = URLS_SERVICE.core.base + URLS_SERVICE.core.status.base;
  protected charName = 'app.main.deviceStatus.chart.chartLabel';

  /**
   * @description
   * Method to get status data chart
   */
  getStatusDataChart(): Observable<any> {
    return this.getStatusDeviceDate()
      .pipe(
        switchMap((response) => {
          if (Object.values(response).every(x => x === 0)) return EMPTY;
          return this.translate.get('app.main.deviceStatus.chart.chartLabel').pipe(
            map((translated) => {
              this.charName = translated;
              return this.chartBarOptionFactory(translated, this.translate, {...response});
            })
          );
        })
      );
  }

  /**
   * @description
   * Method to get status device date
   */
  getStatusDeviceDate(): Observable<StatusDeviceInterface> {
    return this.http.post<StatusDeviceInterface>(this.statusUrl);
  }

  /**
   * @description
   * Method that chart bar option
   * @param {string} title
   * @param {TranslateService} translate
   * @param {StatusDeviceInterface} data
   */
  chartBarOptionFactory(title: string, translate: TranslateService, data?: StatusDeviceInterface): any {
    return {
      tooltip: {
        position: 'bottom',
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '0',
        right: '0',
        bottom: '0',
        top: '0',
        containLabel: false
      },
      label: {
        fontWeight: 'bold'
      },
      xAxis: {
        show: false,
        type: 'value'
      },
      yAxis: {
        show: false,
        type: 'category',
        data: [this.charName]
      },
      barWidth: '100%',
      series: [...this.chartBarSeriesFactory(data)]
    };
  }

  /**
   * @description
   * Method that chart bar series
   * @param {StatusDeviceInterface} data
   */
  chartBarSeriesFactory(data?: StatusDeviceInterface) {
    if (!data) return [];
    const dataSeriesResponse: any[] = [];
    const borderRadius = 4;
    const statusDeviceKeys = Object.keys(data);
    statusDeviceKeys.forEach((item: string, index) => {
      const commonData: any = {
        name: StatusDeviceTextConst[StatusDeviceKeyParse(item)],
        type: 'bar',
        stack: 'total',
        emphasis: {focus: 'series'},
        label: {show: data[StatusDeviceKeyParse(item)] !== 0},
        data: [{
          value: data[StatusDeviceKeyParse(item)],
          id: index
        }]
      };
      const itemStyleFormat: Record<string, string | number[]> = {
        color: StatusDeviceColorConst[StatusDeviceKeyParse(item)]
      };
      if (index === 0) {
        itemStyleFormat['borderRadius'] = [borderRadius, 0, 0, borderRadius];
      } else if (index === (statusDeviceKeys.length - 1)) {
        itemStyleFormat['borderRadius'] = [0, borderRadius, borderRadius, 0];
      }
      commonData.itemStyle = itemStyleFormat;
      dataSeriesResponse.push(commonData);
    });
    return dataSeriesResponse;
  }

}
