/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         11/03/24 - 8:35
 * Module name:  main-router
 * File name:    main-router.guard.ts
 * IDE:          WebStorm
 */

import {CanActivateFn, Router} from '@angular/router';
import {tap} from 'rxjs';
import {inject} from '@angular/core';
import {ZbAuthManagerService} from '@zibanu/auth';

export const mainRouterGuard: CanActivateFn = (_route, _state) => {
  const authService = inject(ZbAuthManagerService);
  const router = inject(Router);
  return authService.isAuthenticated().pipe(
    tap(authenticated => {
      if (!authenticated) {
        router.navigate(['home']).then();
      }
    })
  );
};

