/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
* Project:      frontend
* Developed by: Juan David Pelaez Cumbe
* Date:         5/3/2024 - 07:23
* Module name:  frontend
* File name:    mkad-icon-close.component.ts
* IDE:          WebStorm
*/
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NbButtonModule, NbComponentSize, NbIconModule} from '@nebular/theme';

@Component({
  selector: 'mkad-icon-close',
  standalone: true,
  imports: [NbButtonModule, NbIconModule],
  template: `
    <section class="absolute right-0 top-0">
      <button nbButton ghost status="danger" [size]="size" (click)="clickClose.emit()">
        <nb-icon pack="far" icon="xmark"/>
      </button>
    </section>
  `
})
export class MkadIconCloseComponent {
  @Input({required: false}) size: NbComponentSize = 'medium';
  @Output() clickClose = new EventEmitter<null>();
}
