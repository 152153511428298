/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         22/12/23 - 10:23 PM
 * Module name:  chart-bar-manager.constants
 * File name:    chart-bar-factory.constants.ts
 * IDE:          WebStorm
 */
import {PantoneBaseConst} from '../constants';
import {StatusDeviceInterface} from '../interface';

export const StatusDeviceKeyParse = (item: string): keyof StatusDeviceInterface => {
  return item as keyof StatusDeviceInterface;
};

export enum StatusTypeDeviceEnum {
  comm = 'comm',
  success = 'success',
  warning = 'warning',
  alert = 'alert',
  danger = 'danger'
}

export const StatusDeviceColorConst: Record<StatusTypeDeviceEnum, string> = {
  [StatusTypeDeviceEnum.comm]: PantoneBaseConst.comm,
  [StatusTypeDeviceEnum.success]: PantoneBaseConst.success,
  [StatusTypeDeviceEnum.alert]: PantoneBaseConst.alert,
  [StatusTypeDeviceEnum.warning]: PantoneBaseConst.warning,
  [StatusTypeDeviceEnum.danger]: PantoneBaseConst.danger
};

export const StatusDeviceTextConst: Record<StatusTypeDeviceEnum, string> = {
  [StatusTypeDeviceEnum.comm]: 'Communication',
  [StatusTypeDeviceEnum.success]: 'Success',
  [StatusTypeDeviceEnum.alert]: 'Alert',
  [StatusTypeDeviceEnum.warning]: 'Warning',
  [StatusTypeDeviceEnum.danger]: 'Danger'
};
