import {NgModule} from '@angular/core';
import {FaSpinDirective, MkadCheckLevelDirective} from './directives';

@NgModule({
  declarations: [
    FaSpinDirective,
    MkadCheckLevelDirective,
  ],
  imports: [],
  exports: [
    FaSpinDirective,
    MkadCheckLevelDirective,
  ],
})
export class SharedModule {
}
