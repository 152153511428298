<nb-card class="w-40rem h-22rem">
  <nb-card-body class="p-3">
    <section class="p-1">
      <mkad-alert status="warning"
                  message="{{ 'app.shared.appSettings.field.centerLocation.tooltips' | translate}}"/>
    </section>
    <div class="p-1 flex">
      <section class="col-8 p-0">
        <div leaflet
             class="size__full border-round-left-lg shadow_right"
             [leafletOptions]="configMap"
             [leafletMaxBounds]="leafletMaxBounds"
             [leafletCenter]="centerMap"
             [(leafletZoom)]="zoomMap"
             (leafletClick)="onClickLeaflet($event)"
             (leafletMapReady)="onReadyMap($event)"
             [leafletLayers]="layers">
        </div>
      </section>
      <section class="col-4 gap-2 p-3 h-full flex flex-column justify-content-between data-column">
        <div class="flex flex-column gap-2">
          <span class="w-full">
            <strong>
              <mkad-label-icon mkadStatusIcon="info" left="arrows-to-circle"
                          text="{{'app.shared.appSettings.field.centerLocation.button.mapMakers.zoom' |translate}}"/>
            </strong>
            {{ zoomMap }}
          </span>
          <span class="w-full">
          <strong> <mkad-label-icon mkadStatusIcon="info" left="location-dot"
                               text="{{'app.shared.appSettings.field.centerLocation.button.mapMakers.latitude' |translate}}"/></strong>
            {{ centerMap.lat }}
        </span>
          <span class="w-full">
          <strong><mkad-label-icon mkadStatusIcon="info" left="location-dot"
                              text="{{'app.shared.appSettings.field.centerLocation.button.mapMakers.longitude' |translate}}"/></strong>
            {{ centerMap.lng }}
        </span>
        </div>
        <div>
          <button nbButton outline size="small" fullWidth status="success" class="mb-2" (click)="onClickSave()"
                  nbTooltip="{{'app.shared.appSettings.footer.button.save.tooltip'|translate}}">
            {{ 'app.shared.appSettings.footer.button.save.label'|translate }}
          </button>
          <button nbButton outline size="small" fullWidth status="danger" (click)="onClickClose()"
                  nbTooltip="{{'app.shared.appSettings.footer.button.cancel.tooltip'|translate}}">
            {{ 'app.shared.appSettings.footer.button.cancel.label'|translate }}
          </button>
        </div>
      </section>
    </div>
  </nb-card-body>
</nb-card>
