import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {NgxEchartsDirective} from 'ngx-echarts';

@Component({
  selector: 'mkad-chart-bar',
  standalone: true,
  imports: [NgxEchartsDirective],
  template: `
    hola
    <!--<div echarts id="status-device-chart" class="h-full flex align-items-center" (chartClick)="onClickChart($event)"
         [initOpts]="{
            renderer: 'svg',
              width: 'auto',
              height: '50'
            }"
         [options]="chartConfig"
    ></div>-->
`,
})
export class MkadChartBarComponent implements OnChanges {
  @Input() data!: any;
  @Output() clickEvent = new EventEmitter<any>();
  chartConfig: any = {};

  //:todo crear instancia del chart y agregar evento chartInit para definir feature de cambio de tema

  /**
   * @description
   * Method that responds to the click on the graphic
   * @param selected option config references
   */
  onClickChart({data}: any) {
    console.log(data);
    if (data) {
      this.clickEvent.emit(data);
    }
  }

  /**
   * @description
   * Lifecycle hook event responses
   */
  ngOnChanges(): void {
    this.setChartConfig();
  }

  /**
   * @description
   * Method assigning the configuration for the bar chart
   */
  setChartConfig(): void {
    if (this.data) this.chartConfig = this.data;
  }
}
