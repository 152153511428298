<mkad-wrapper-dialog
  width="24rem"
  iconLeft="gears"
  footerAlign="between"
  [header]="'app.shared.appSettings.header.title'|translate"
  [nbSpinner]="isLoading"
  (clickClose)="handlerClose()">
  @if (formSettings) {
    <form body class="flex flex-column w-full gap-3 mkad__form" [formGroup]="formSettings"
          (ngSubmit)="handlerSubmit()">
      <section class="field__wrap">
        <label for="decimal" class="font-bold">
          {{ 'app.shared.appSettings.field.decimal.label' | translate }}
          <sup>
            <nb-icon icon="circle-exclamation" pack="far"
                     nbTooltip="{{'app.shared.appSettings.field.decimal.tooltips'|translate}}"/>
          </sup>
        </label>
        <nb-select formControlName="decimal" fullWidth id="decimal" size="small"
                   placeholder="{{'app.shared.appSettings.field.decimal.placeholder' | translate}}"
                   nbTooltip="{{'app.shared.appSettings.field.decimal.tooltips'|translate}}">
          @for (decimal of decimalList; track decimal) {
            <nb-option [value]="decimal">{{ decimal }}</nb-option>
          }
        </nb-select>
      </section>
      <section class="field__wrap">
        <label for="export" class="font-bold">
          {{ 'app.shared.appSettings.field.export.label'|translate }}
          <sup>
            <nb-icon icon="circle-exclamation" pack="far"
                     nbTooltip="{{'app.shared.appSettings.field.export.tooltips'|translate}}"></nb-icon>
          </sup>
        </label>
        <nb-radio-group formControlName="export" class="block" status="info" id="export">
          @for (option of exportTypeList; track option.id) {
            <nb-radio
              class="inline-flex"
              [value]="option.id"
              [nbTooltip]="option.name">
              {{ option.name }}
            </nb-radio>
          }
        </nb-radio-group>
      </section>
      <section class="center_location flex flex-column gap-1">
        <label class="font-bold">{{ 'app.shared.appSettings.field.centerLocation.label' | translate }}
          <sup>
            <nb-icon icon="circle-exclamation" pack="far"
                     nbTooltip="{{'app.shared.appSettings.field.centerLocation.tooltips'|translate}}"/>
          </sup></label>
        <button nbButton outline fullWidth size="small" status="info" type="button" (click)="openMapDialog()">
          {{ 'app.shared.appSettings.field.centerLocation.button.label'|translate }}
          <nb-icon icon="map-location-dot"/>
        </button>
        <section class="map_view flex flex-column">
          <div class="flex w-full h-8rem py-2">
            <mkad-map-basic class="w-full" [readonly]="true"
                            [center]="utilsMap.latLng(pointMarked[0], pointMarked[1])"
                            [layers]="[utilsMap.marker(utilsMap.latLng(pointMarked[0], pointMarked[1]))]"
                            [zoom]="formSettings.controls['zoom'].value"/>
          </div>
          @if (pointMarked) {
            <div class="grid">
              <span class="col-3">
                <mkad-label-icon mkadStatusIcon="info" left="arrows-to-circle"
                            text="{{'app.shared.appSettings.field.centerLocation.mapView.zoom' |translate}}"/>
                <strong class="text__truncate" [nbTooltip]="formSettings.controls['zoom'].value">
                  {{ formSettings.controls['zoom'].value }}
                </strong>
              </span>
              <span class="col-4">
              <mkad-label-icon mkadStatusIcon="info" left="location-dot"
                          text="{{'app.shared.appSettings.field.centerLocation.mapView.latitude' | translate}}"/>
              <strong class="text__truncate" [nbTooltip]="pointMarked[0].toString()">
                {{ pointMarked[0] }}
              </strong>
              </span>
              <span class="col-4">
                <mkad-label-icon mkadStatusIcon="info" left="location-dot"
                            text="{{'app.shared.appSettings.field.centerLocation.mapView.longitude'|translate}}"/>
                <strong class="text__truncate" [nbTooltip]="pointMarked[1].toString()">
                  {{ pointMarked[1] }}
                </strong>
              </span>
            </div>
          }
        </section>
      </section>
      <section class="field__wrap">
        <label for="data_null" class="font-bold">
          {{ 'app.shared.appSettings.field.nullManage.label'|translate }}
          <sup>
            <nb-icon icon="circle-exclamation"
                     nbTooltip="{{'app.shared.appSettings.field.nullManage.tooltips'|translate}}"/>
          </sup>
        </label>
        <nb-select formControlName="data_null" fullWidth id="data_null" size="small"
                   placeholder="{{'app.shared.appSettings.field.nullManage.placeholder' | translate}}"
                   nbTooltip="{{'app.shared.appSettings.field.nullManage.tooltips'|translate}}">
          @for (item of nullManageList; track item.id) {
            <nb-option [value]="item.id">{{ item.name }}</nb-option>
          }
        </nb-select>
      </section>
    </form>
  }
  <ng-container footer>
    <button nbButton outline size="small" status="danger" type="button" (click)="handlerClose()"
            nbTooltip="{{ 'app.shared.appSettings.footer.button.cancel.tooltip'|translate }}">
      {{ 'app.shared.appSettings.footer.button.cancel.label'|translate }}
    </button>
    <button nbButton outline size="small" status="success" type="button" (click)="handlerSubmit()"
            nbTooltip="{{ 'app.shared.appSettings.footer.button.save.tooltip'|translate }}">
      {{ 'app.shared.appSettings.footer.button.save.label'|translate }}
    </button>
  </ng-container>
</mkad-wrapper-dialog>
