/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         20/12/23 - 3:39 PM
 * Module name:  about-us.service.ts
 * File name:    about-us.service.ts
 * IDE:          WebStorm
 */

import {MkadBaseService} from '../mkad-base.service';
import {URLS_SERVICE} from '../url-service.const';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AboutUsDataInterface} from '../../interface';


@Injectable()
export class AboutUsService extends MkadBaseService {
  readonly coreEndpoints = URLS_SERVICE.core;
  readonly aboutUsUrl = this.coreEndpoints.base + this.coreEndpoints.about.base;

  /**
   * @description
   * Method that return the about us data
   */
  getAboutUsData(): Observable<AboutUsDataInterface | null> {
    return this.http.post(this.aboutUsUrl);
  }
}
