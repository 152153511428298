/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ZbAuthCheckerService} from '@zibanu/auth';
import {MkadCheckLevelType} from '../../enums';

/**
 * Directive that validates whether it has access to the current element and disables or disallows it
 * @selector mkadCheckLevel
 * @standalone True
 */
@Directive({
  selector: '[mkadCheckLevel]',
})
export class MkadCheckLevelDirective implements OnChanges {
  @Input({required: true})
  minLevel = 0;
  @Input({required: false})
  elementType: MkadCheckLevelType = MkadCheckLevelType.control;
  @Input({required: false})
  disableClass = 'disabled';
  @Output()
  mkadClick = new EventEmitter<Event>();

  private readonly authChecker = inject(ZbAuthCheckerService);

  /**
   * Constructor class
   * @param _el ElementRef dependency injection
   */
  constructor(private readonly _el: ElementRef) {
  }

  /**
   * @description
   * Method that response to onChange life cycle
   * @param _changes
   */
  ngOnChanges(_changes: SimpleChanges): void {
    this.validateLevel();
  }

  /**
   * @description
   * Method that validates whether it is enabled or on the element
   */
  validateLevel(): void {
    this.authChecker.isGranted(this.minLevel)
      .subscribe(
        {
          next: (canAccess: boolean) => {
            if (this.elementType === MkadCheckLevelType.div) {
              this._el.nativeElement.classList[canAccess ? 'remove' : 'add'](this.disableClass);
            } else {
              this._el.nativeElement.disabled = !canAccess;
            }
          },
        },
      );
  }

  /**
   * Method that listens and validates the click on the host
   * @param event original event
   */
  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.elementType === MkadCheckLevelType.div) {
      if (!this._el.nativeElement.classList.contains(this.disableClass)) this.mkadClick.emit(event);
    } else {
      if (!this._el.nativeElement.disabled) this.mkadClick.emit(event);
    }
  }
}
