/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2023 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2023 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         11/07/23 - 12:43 PM
 * Module name:  terms-conditions.constants
 * File name:    terms-conditions.constants.ts
 * IDE:          WebStorm
 */

import {LanguageAvailableType, StringSignatureType} from '../types';
import {TermsConditionsPathEnum} from '../enums';

export const TERMS_CONDITIONS_CONST: StringSignatureType = {
  getUrl: (lang: LanguageAvailableType): string => {
    return TermsConditionsPathEnum[lang];
  }
};
