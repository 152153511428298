/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         10/04/24 - 12:47
 * Module name:  translate-object-list.utils
 * File name:    translate-object-list.utils.ts
 * IDE:          WebStorm
 */


import {TranslateService} from '@ngx-translate/core';

/**
 * @description
 * Method that translates an object list
 * @param translate list you want to translate
 * @param list list you want to translate
 * @param keyToTranslate keys of the object to be translated
 */
export function translateObjectList<T = object>(translate: TranslateService, list: T[], keyToTranslate: string[]): T[] {
  list.forEach((item: T, itemIndex: number): void => {
    keyToTranslate.forEach((keyItem: string): void => {
      translate.get(item[keyItem as keyof T] as string).subscribe((result: string): void => {
        list[itemIndex][keyItem as keyof T] = result as T[keyof T];
      });
    });
  });
  return list;
}


/**
 * @description
 * Method that translates an object list
 * @param translate list you want to translate
 * @param list list you want to translate
 */
export function translateLists(translate: TranslateService, list: string[]): string[] {
  list.forEach((item: string, itemIndex: number): void => {
    translate.get(item).subscribe((result: string): void => {
      list[itemIndex] = result;
    });
  });
  return list;
}
