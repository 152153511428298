import {Component, EventEmitter, input, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NbActionsModule, NbIconModule, NbTooltipModule} from '@nebular/theme';
import {NgOptimizedImage} from '@angular/common';
import {LanguageAvailableType} from '@shared/types';
import {UserCustomerDataInterface} from '@shared/interface';

@Component({
  selector: 'mkad-frame-footer',
  templateUrl: 'frame-footer.component.html',
  standalone: true,
  imports: [TranslateModule, NgOptimizedImage, NbActionsModule, NbTooltipModule, NbIconModule]
})
export class FrameFooterComponent {
  @Input({required: false}) lang: LanguageAvailableType = 'es';
  @Input({required: true}) politiesUrl = '';
  customerData = input.required<UserCustomerDataInterface | null>();
  @Output() clickLeftIcon = new EventEmitter();
  url = 'https://www.cqinversiones.co';

  /**
   * @description
   * Returns the string it receives or an empty string if nothing is received.
   * @param value
   */
  parseString(value: string | null | undefined): string {
    return <string>value
  }
}
