import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ZbBaseComponent} from '@zibanu/ui';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NbCardModule, NbIconModule, NbThemeService, NbTooltipModule, NbUserModule} from '@nebular/theme';
import {NgClass, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule} from '@angular/cdk/overlay';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {MkadStatusDeviceComponent} from '../status-device/status-device.component';
import {UserOverlayComponent} from '../user-overlay/user-overlay.component';
import {SharedModule} from '@shared/shared.module';

@Component({
  selector: 'mkad-frame-header',
  templateUrl: './frame-header.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgOptimizedImage,
    RouterLink,
    NgClass,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    CdkTrapFocus,
    OverlayModule,
    NbIconModule,
    NbTooltipModule,
    NbCardModule,
    NbUserModule,
    UserOverlayComponent,
    MkadStatusDeviceComponent,
    SharedModule,
  ],
})
export class FrameHeaderComponent extends ZbBaseComponent implements OnInit {
  @Input({required: true}) isActive = false;
  @Input({required: true}) name?: string;
  @Input({required: true}) avatarUrl?: string;
  @Input({required: true}) perpetualSession: boolean = false;
  @Input({required: true}) showAvatarAlert: boolean = false;
  @Output() clickToggleIcon = new EventEmitter<null>();
  @Output() clickAvatar = new EventEmitter<null>();
  logoUrl = '';
  showOverlay = false;

  /**
   * @description
   * Constructor class
   * @param translate TranslateService dependency injection
   * @param theme NbThemeService dependency injection
   */
  constructor(override translate: TranslateService,
              private readonly theme: NbThemeService) {
    super(translate);
    this.theme.onThemeChange().subscribe({
      next: (newTheme) => this.setLogoUrl(newTheme.name)
    });
  }

  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  override ngOnInit() {
    super.ngOnInit();
  }

  /**
   * @description
   * Method that return the url of logotype
   */
  avatarPicture(): string {
    return this.avatarUrl ?? '';
  }

  /**
   * @description
   * Method that return the logo url
   */
  setLogoUrl(theme: string) {
    this.logoUrl = `assets/image/meerkad_${theme !== 'corporate' ? 'dark' : 'light'}.webp`;
  }

  /**
   * @description
   * Method that response to click on Overlay
   */
  onClickOverlay() {
    this.showOverlay = false;
  }

  /**
   * @description
   * Method that response to click on user avatar
   */
  onClickUserAvatar() {
    this.showOverlay = true;
  }

}
