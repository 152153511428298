/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         10/04/24 - 9:54
 * Module name:  parse-date-to-string.utils
 * File name:    parse-date-to-string.utils.ts
 * IDE:          WebStorm
 */

export const parseToStandardDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};
