import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import { Subject, lastValueFrom, from, firstValueFrom } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i1$1 from '@nebular/auth';
import * as i2 from '@ngx-translate/core';

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         21/08/23 - 12:16
* Module name:  Zibanu Angular
* File name:    zb-lib-config.services.ts
* IDE:          WebStorm
*/
/**
 * Injection Token config for zibanu-lib lib
 */
const ZbLibConfigService = new InjectionToken('ZbLibConfigInterface');

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
/*
 * Project:      zibanu
 * Developed by: Juan David Pelaez Cumbe
 * Date:         26/01/24 - 12:24
 * Module name:  storage-response-dto.interfaces
 * File name:    storage-response-dto.interfaces.ts
 * IDE:          WebStorm
 */
/**
 * keys/props that the system allows to store in LS (local storage)
 */
var ZbStorageKeysEnum;
(function (ZbStorageKeysEnum) {
  ZbStorageKeysEnum["mail"] = "mail";
  ZbStorageKeysEnum["access"] = "access";
  ZbStorageKeysEnum["stored"] = "stored";
})(ZbStorageKeysEnum || (ZbStorageKeysEnum = {}));

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         21/08/23 - 12:06
* Module name:  Zibanu Angular
* File name:    index.ts
* IDE:          WebStorm
*/

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
 * Project:      zibanu
 * Developed by: Juan David Pelaez Cumbe
 * Date:         27/10/23 - 7:15 APM
 * Module name:  ZbSecureStorageService
 * File name:    zb-secure-storage.services.ts
 * IDE:          WebStorm
 */
/**
 *  Facade services that manages the ZbSecureStorage class
 */
class ZbSecureStorageService {
  constructor() {
    this.storage = new ZbSecureStorage();
    this.storedUserChange$ = new Subject();
  }
  /**
   * @description
   * Method that fires next event of storedUserChange$ observable
   * @private
   */
  dispatchNextEvent() {
    this.storedUserChange$.next(this.getUserCredentials());
  }
  /**
   * @description
   * Method that store the data of the user logged
   * @param mail email of the user
   * @param access password of the user
   */
  storeCredentials(mail, access) {
    this.storage.setItem(ZbStorageKeysEnum.mail, mail);
    this.storage.setItem(ZbStorageKeysEnum.access, access);
    this.storage.setItem(ZbStorageKeysEnum.stored, 'true');
    this.dispatchNextEvent();
  }
  /**
   * @description
   * Method that delete tha data stored of the user
   */
  deleteCredentialsStored() {
    this.storage.removeItem(ZbStorageKeysEnum.mail);
    this.storage.removeItem(ZbStorageKeysEnum.access);
    this.storage.removeItem(ZbStorageKeysEnum.stored);
    this.dispatchNextEvent();
  }
  /**
   * @description
   * Method that return the data {ZbZbStorageResponseDtoInterface} from the store
   */
  getUserCredentials() {
    const sessionStored = !!this.storage.getItem(ZbStorageKeysEnum.stored);
    return {
      hasSessionStored: sessionStored,
      data: sessionStored ? {
        mail: this.storage.getItem(ZbStorageKeysEnum.mail) ?? '',
        access: this.storage.getItem(ZbStorageKeysEnum.access) ?? ''
      } : null
    };
  }
  static {
    this.ɵfac = function ZbSecureStorageService_Factory(t) {
      return new (t || ZbSecureStorageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZbSecureStorageService,
      factory: ZbSecureStorageService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZbSecureStorageService, [{
    type: Injectable
  }], null, null);
})();

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         16/08/23 - 18:20
* Module name:  Zibanu Angular
* File name:    zb-http-client.ts
* IDE:          WebStorm
*/
/**
 * Class to encapsulate original HttpClient and incorporate facilities to invoke it.
 */
class ZbHttpClient {
  /**
   * Constructor method for class
   * @param httpClient - Injectable HttpClient services
   * @param zbConfig - Injectable ZbLibConfigService to get config options
   */
  constructor(httpClient, zbConfig) {
    this.httpClient = httpClient;
    this.zbConfig = zbConfig;
  }
  /**
   * Method to construct headers by default.
   * @private
   */
  getHttpHeaders() {
    const headers = new HttpHeaders();
    headers.append('accept', 'application/json');
    headers.append('content-types', 'application/json');
    return headers;
  }
  /**
   * Method to validate right url format.
   * @param url - Url to validate
   * @private
   */
  validateUrl(url) {
    url = url.endsWith('/') ? url : url + '/';
    return url;
  }
  /**
   * Method to get base endpoint from zbConfig values.
   * @private
   */
  getBaseEndpoint() {
    return this.validateUrl(this.zbConfig.baseEndpoint);
  }
  /**
   * Method to get validated url with baseEndpoint and endpoint
   * @param endpoint - endpoint string to create url.
   * @private
   */
  getUrl(endpoint) {
    return this.validateUrl(this.getBaseEndpoint() + endpoint);
  }
  /**
   * Method to override default get method from HttpClient.
   * @param endpoint - endpoint of getting data
   * @param headers - headers to override default headers
   */
  get(endpoint, headers) {
    const url = this.validateUrl(this.getBaseEndpoint() + endpoint);
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    return this.httpClient.get(url, {
      headers: headers
    });
  }
  /**
   * Method to expose  async get request from default HttpClient class.
   * @param endpoint - endpoint from get data
   * @param headers - headers to override default headers
   */
  async asyncGet(endpoint, headers) {
    const url = this.validateUrl(this.getBaseEndpoint() + endpoint);
    const zbResponse = {
      status: 500
    };
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    await lastValueFrom(this.httpClient.get(url, {
      headers: headers,
      observe: 'response'
    })).then(response => {
      zbResponse.status = response.status;
      zbResponse.body = response.body;
    }).catch(errorResponse => {
      zbResponse.status = errorResponse.status;
      zbResponse.errorMessage = errorResponse.error.message;
      zbResponse.errorCode = errorResponse.error.detail;
    });
    return zbResponse;
  }
  /**
   * Method to expose observable post request from default HttpClient class.
   * @param endpoint - endpoint of getting data
   * @param body - data body to send to the backend
   * @param headers - headers to override default headers
   * @param context - headers to override default headers
   */
  post(endpoint, body, headers, context) {
    const url = this.getUrl(endpoint);
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    return this.httpClient.post(url, body, {
      headers,
      context
    });
  }
  /**
   * Method to expose async post request from default HttpClient class.
   * @param endpoint - endpoint of getting data
   * @param body - data body to send to the backend
   * @param headers - headers to override default headers
   */
  async asyncPost(endpoint, body, headers) {
    const url = this.getUrl(endpoint);
    const zbResponse = {
      status: 500
    };
    // Build a header
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    await lastValueFrom(this.httpClient.post(url, body, {
      headers: headers,
      observe: 'response'
    })).then(response => {
      zbResponse.status = response.status;
      zbResponse.body = response.body;
    }).catch(errorResponse => {
      zbResponse.status = errorResponse.status;
      zbResponse.errorMessage = errorResponse.error.message;
      zbResponse.errorCode = errorResponse.error.detail;
    });
    return zbResponse;
  }
  /**
   * Method to expose observable put request from default HttpClient class.
   * @param endpoint - endpoint of getting data
   * @param body - data body to send to the backend
   * @param headers - headers to override default headers
   */
  put(endpoint, body, headers) {
    const url = this.getUrl(endpoint);
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    return this.httpClient.put(url, body, {
      headers: headers
    });
  }
  /**
   * Method to expose async put request from default HttpClient class.
   * @param endpoint - endpoint of getting data
   * @param body - data body to send to the backend
   * @param headers - headers to override default headers
   */
  async asyncPut(endpoint, body, headers) {
    const url = this.getUrl(endpoint);
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    return await lastValueFrom(this.httpClient.put(url, body, {
      headers: headers,
      observe: 'response'
    }));
  }
  /**
   * Method to expose observable patch request from default HttpClient class
   * @param endpoint - endpoint of getting data
   * @param body - data body to send to the backend
   * @param headers - headers to override default headers
   */
  patch(endpoint, body, headers) {
    const url = this.getUrl(endpoint);
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    return this.httpClient.patch(url, body, {
      headers: headers
    });
  }
  /**
   * Method to expose async patch request from default HttpClient class
   * @param endpoint - endpoint of getting data
   * @param body - data body to send to the backend
   * @param headers - headers to override default headers
   */
  async asyncPatch(endpoint, body, headers) {
    const url = this.getUrl(endpoint);
    if (!headers) {
      headers = this.getHttpHeaders();
    }
    return await lastValueFrom(this.httpClient.patch(url, body, {
      headers: headers,
      observe: 'response'
    }));
  }
  static {
    this.ɵfac = function ZbHttpClient_Factory(t) {
      return new (t || ZbHttpClient)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(ZbLibConfigService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZbHttpClient,
      factory: ZbHttpClient.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZbHttpClient, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ZbLibConfigService]
    }]
  }], null);
})();

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         21/08/23 - 16:26
* Module name:  Zibanu Angular
* File name:    index.ts
* IDE:          WebStorm
*/

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         15/08/23 - 18:25
* Module name:  Zibanu Angular
* File name:    zb-http-interceptor.ts
* IDE:          WebStorm
*/
/**
 * Injectable interceptor class to set headers with "authorization" and "accept-languages" headers.
 */
class ZbHttpInterceptor {
  /**
   * Default constructor class
   * @param authService - Injectable Nebular Auth Service for auth purposes.
   * @param zbConfig - Inject from ZbLibConfigService, the ZbLibConfig values
   * @param translateService - TranslateService DI
   */
  constructor(authService, zbConfig, translateService) {
    this.authService = authService;
    this.zbConfig = zbConfig;
    this.translateService = translateService;
  }
  /**
   * Intercept method to inject "Accept-Language" header and "Authorization" header if the user is authenticated.
   * @param httpRequest - Http Request Object
   * @param httpHandler - Http Handler for request.
   */
  intercept(httpRequest, httpHandler) {
    const newReq = httpRequest.clone({
      headers: httpRequest.headers.set('Accept-Language', this.translateService.currentLang ?? 'es')
    });
    return from(this.handle(newReq, httpHandler));
  }
  /**
   * Private method to that encapsulates the HttpHandler class to invoke it asynchronously
   * @param httpRequest - HttpRequest object
   * @param httpHandler - HttpHandler class
   * @private async
   */
  async handle(httpRequest, httpHandler) {
    const nbToken = await firstValueFrom(this.authService.getToken());
    const isAuth = await firstValueFrom(this.authService.isAuthenticated());
    let newRequest = httpRequest.clone();
    if (isAuth) {
      const tokenString = this.zbConfig.tokenKeyword + ' ' + nbToken.getValue();
      newRequest = newRequest.clone({
        headers: httpRequest.headers.set('Authorization', tokenString)
      });
    }
    return await lastValueFrom(httpHandler.handle(newRequest));
  }
  static {
    this.ɵfac = function ZbHttpInterceptor_Factory(t) {
      return new (t || ZbHttpInterceptor)(i0.ɵɵinject(i1$1.NbAuthService), i0.ɵɵinject(ZbLibConfigService), i0.ɵɵinject(i2.TranslateService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ZbHttpInterceptor,
      factory: ZbHttpInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ZbHttpInterceptor, [{
    type: Injectable
  }], () => [{
    type: i1$1.NbAuthService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ZbLibConfigService]
    }]
  }, {
    type: i2.TranslateService
  }], null);
})();

/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
 * Project:      zibanu
 * Developed by: Juan David Pelaez Cumbe
 * Date:         26/10/23 - 3:22 PM
 * Module name:  secure-storage.ts
 * File name:    secure-storage.ts
 * IDE:          WebStorm
 */
/**
 * class to manage the storage facility
 */
class ZbSecureStorage {
  constructor() {
    /**
     * @description
     * Method that clears all keys stored
     */
    this.clear = () => localStorage.clear();
    /**
     * @description
     * Method that returns the name of the nth key received
     */
    this.key = index => localStorage.key(index);
    /**
     * @description
     * Method that will remove the received key from the store
     * @param key key to will be removed from the store
     */
    this.removeItem = key => localStorage.removeItem(key);
  }
  /**
   * @description
   * Method to return the encrypted the data received
   * @param value
   */
  static {
    this.encode = value => btoa(value);
  }
  /**
   * @description
   * Method to return the unencrypted the data
   * @param value
   */
  static {
    this.decode = value => atob(value);
  }
  /**
   * @description
   * Method that return the count of items stored in the store
   */
  get length() {
    return localStorage.length;
  }
  /**
   * Method to set encrypted the item in the store
   * @param key key to which the data are to be assigned
   * @param value Data to be stored
   */
  setItem(key, value) {
    localStorage.setItem(key, ZbSecureStorage.encode(value));
  }
  /**
   * @description
   * Method to get unencrypted a specific from the store
   * @param key key of the item to get
   */
  getItem(key) {
    const value = localStorage.getItem(key);
    if (!value) return null;
    return ZbSecureStorage.decode(value);
  }
}

/**
 * @description
 * @const
 * Number of decimal places in latitude and longitude by default
 */
const defaultCoordsDecimal = 8;
/**
 * @description
 * @const
 * Minimum default password length
 */
const TEN = 10;
/**
 * @description
 * @const
 * Minimum default password length
 */
const defaultMinLength = 8;
/**
 * @description
 * @const
 * Minimum default password length
 */
const defaultHexLength = 2;
/**
 * @description
 * @const
 * Maximum default password length
 */
const defaultMaxLength = 32;
/**
 * @description
 * Index for the dollar sign
 */
const dollarSimbolIndex = 1;
/**
 * @description
 * Upper case alphabetic characters
 * 'A-Z'
 */
const uppercaseAlphabetic = 'A-Z';
/**
 * @description
 * Lower case alphabetic characters
 * 'a-z'
 */
const lowercaseAlphabetic = 'a-z';
/**
 * @description
 * Upper and lower case alphabetic characters
 * 'A-Za-z'
 */
const alphabetic = uppercaseAlphabetic + lowercaseAlphabetic;
/**
 * @description
 * numeric characters
 * '\\d'
 */
const numbers = '\\d';
/**
 * @description
 * numeric characters
 * '0-9'
 */
const numeric = '0-9';
/**
 * @description
 * numeric characters
 * '0-9'
 */
const numbersRange = (start, end) => `${start}-${end}`;
/**
 * @description
 * Numerical and alphabetical characters in upper and lower case letters
 * 'A-Za-z0-9'
 */
const alphanumeric = alphabetic + numeric;
/**
 * @description
 * Upper and lower case alphabetic characters
 * 'A-Z0-9'
 */
const alphanumericUppercase = uppercaseAlphabetic + numeric;
/**
 * @description
 * Special characters allowed
 * '¡!#$%&()*+\\-.@/_'
 */
const specialChars = '¡!#$%&()*+\\-.@/_';
/**
 * @description
 * Special characters allowed, numeric and alphabetic upper and lower case letters
 * 'A-Za-z0-9¡!#$%&()*+\\-.@/_'
 */
const special = alphanumeric + specialChars;
/**
 * @description
 * Space
 * \s
 */
const space = '\\s';
/**
 * @description
 * Special characters allowed, numeric, space and alphabetic upper and lower case letters
 * ' A-Za-z0-9¡!#$%&()*+\\-.@/_'
 */
const paragraph = space + special;
/**
 * @description
 * Special characters allowed, numeric, space and alphabetic upper and lower case letters
 * ^(?:\d{1,10}(?:\.\d{1,8})?|\.\d{1,8})$
 */
const decimal = (maxIntegers, maxDecimals) => `(?:\\d{1,${maxIntegers}}(?:\\.\\d{1,${maxDecimals}})?|\\.\\d{1,${maxDecimals}})`;
/**
 * @description
 * Special characters allowed, numeric, space and alphabetic upper and lower case letters
 * '[0x]+[0-9a-fA-F]{2,${maxLength}}$'
 */
const hex = maxLength => `[0x]+[0-9a-fA-F]{2,${maxLength}}`;
/**
 * @description
 * Special characters allowed, numeric, space and alphabetic upper and lower case letters
 * `^(0x[\\da-fA-F]{${maxLength}})(,0x[\\da-fA-F]{${maxLength}})*$`
 */
const hexList = maxLength => `(${hex(maxLength)})(,0x[\\da-fA-F]{${maxLength}})*`;
/**
 * @description
 * Email validator
 * '[A-Za-z0-9._-]+@[A-Za-z0-9]+\\.[A-Za-z]{2,}(?:\\.[A-Za-z]{2,})?'
 */
const mail = '[A-Za-z0-9._-]+@[A-Za-z0-9]+\\.[A-Za-z]{2,}(?:\\.[A-Za-z]{2,})?';
/**
 * @description
 * Validator for latitude
 * @param decimals cantidad de decimales en las coordenadas
 */
const latitude = decimals => `(\\+|-)?(?:90(?:(?:\\.0{1,${decimals}})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,${decimals}})?))`;
/**
 * @description
 * Validator for longitude
 * @param decimals cantidad de decimales en las coordenadas
 */
const longitude = decimals => `(\\+|-)?(?:180(?:(?:\\.0{1,${decimals}})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,${decimals}})?))`;
/**
 * @description
 * Validator for weak passwords
 * @param minLength minimum password length
 * @param maxLength maximum password length
 */
const weakPassword = (minLength = defaultMinLength, maxLength = defaultMaxLength) => {
  return `(?=.{${minLength},${maxLength}$)(?!.*[ñÑ])(?!.*\\s)[a-zA-Z¡!#$%&()*+.\\-@/_0-9]+`;
};
/**
 * @description
 * Validator for secure passwords
 * @param minLength minimum password length
 * @param maxLength maximum password length
 */
const securePassword = (minLength = defaultMinLength, maxLength = defaultMaxLength) => {
  return `(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[¡!#$%&()*+.-@/_])[a-zA-Z0-9¡!#$%&()*+.-@/_]{${minLength},${maxLength}`;
};
/**
 * @description
 * @enum
 * Type of expression to return
 */
var ZbRegexEnum;
(function (ZbRegexEnum) {
  /**
   * @description
   * pass the custom in config
   */
  ZbRegexEnum[ZbRegexEnum["custom"] = 0] = "custom";
  /**
   * @implements
   * 'A-Za-z'
   */
  ZbRegexEnum[ZbRegexEnum["alphabetic"] = 1] = "alphabetic";
  /**
   * @implements
   * '0-9'
   */
  ZbRegexEnum[ZbRegexEnum["numeric"] = 2] = "numeric";
  /**
   * @implements
   * 'A-Za-z0-9'
   */
  ZbRegexEnum[ZbRegexEnum["alphanumeric"] = 3] = "alphanumeric";
  /**
   * @implements
   * 'A-Z'
   */
  ZbRegexEnum[ZbRegexEnum["uppercase"] = 4] = "uppercase";
  /**
   * @implements
   * 'A-Za-z0-9¡!#$%&()*+\\-.@/_'
   */
  ZbRegexEnum[ZbRegexEnum["specialAlphanumeric"] = 5] = "specialAlphanumeric";
  /**
   * @implements
   * '0-9'
   */
  ZbRegexEnum[ZbRegexEnum["phone"] = 6] = "phone";
  /**
   * @description
   * Validation for latitudes applies
   */
  ZbRegexEnum[ZbRegexEnum["latitude"] = 7] = "latitude";
  /**
   * @description
   * Validation for longitudes applies
   */
  ZbRegexEnum[ZbRegexEnum["longitude"] = 8] = "longitude";
  /**
   * @description
   * Validation for e-mails applies
   */
  ZbRegexEnum[ZbRegexEnum["mail"] = 9] = "mail";
  /**
   * @description
   * Apply validation for weak passwords
   */
  ZbRegexEnum[ZbRegexEnum["securePassword"] = 10] = "securePassword";
  /**
   * @description
   * Apply validation for secure passwords
   */
  ZbRegexEnum[ZbRegexEnum["weakPassword"] = 11] = "weakPassword";
  /**
   * @implements
   * ' A-Za-z0-9¡!#$%&()*+\\-.@/_'
   */
  ZbRegexEnum[ZbRegexEnum["paragraph"] = 12] = "paragraph";
  /**
   * @implements
   * [0x]+[0-9a-fA-F]{2,${maxDupla}}$
   */
  ZbRegexEnum[ZbRegexEnum["hex"] = 13] = "hex";
  /**
   * @implements
   *  ([0x]+[0-9a-fA-F]{2,${maxDupla}})(,0x[\\da-fA-F]{${param}})*
   */
  ZbRegexEnum[ZbRegexEnum["hexList"] = 14] = "hexList";
  /**
   *
   * @implements
   *  ^(?:\d{1,${max}}(?:\.\d{1,${maxDecimal}})?|\.\d{1,${maxDecimal}})$
   */
  ZbRegexEnum[ZbRegexEnum["decimal"] = 15] = "decimal";
  /**
   * @implements
   *  ^[${start}-${end}]$
   */
  ZbRegexEnum[ZbRegexEnum["numberRange"] = 16] = "numberRange";
})(ZbRegexEnum || (ZbRegexEnum = {}));
/**
 * Class for the generation of regular expressions
 */
class ZbRegexClass {
  /**
   * @description
   * constructor class
   * @param type Type of regular expression
   * @param config Additional configuration for the regular expression
   */
  constructor(type, config) {
    this.type = type;
    this.config = config;
    // circumflex, beginning of chain
    this.startOfChain = '^';
    // end of the chain
    this.endOfChain = '+$';
    // Number of decimal places in latitude and longitude
    this.coordsDecimal = defaultCoordsDecimal;
  }
  /**
   * @description
   * Method that returns the body of the expression according to type
   * @private
   */
  get processRegexType() {
    if (this.type === ZbRegexEnum.alphabetic) return alphabetic;
    if (this.type === ZbRegexEnum.numeric) return numbers;
    if (this.type === ZbRegexEnum.alphanumeric) return alphanumeric;
    if (this.type === ZbRegexEnum.uppercase) return alphanumericUppercase;
    if (this.type === ZbRegexEnum.specialAlphanumeric) return special;
    if (this.type === ZbRegexEnum.phone) return numbers;
    if (this.type === ZbRegexEnum.paragraph) return paragraph;
    if (this.type === ZbRegexEnum.mail) return mail;
    if (this.type === ZbRegexEnum.latitude) return latitude(this.config?.coordsDecimals ?? this.coordsDecimal);
    if (this.type === ZbRegexEnum.longitude) return longitude(this.config?.coordsDecimals ?? this.coordsDecimal);
    if (this.type === ZbRegexEnum.securePassword) return securePassword();
    if (this.type === ZbRegexEnum.weakPassword) return weakPassword();
    if (this.type === ZbRegexEnum.hex) return hex(this.config?.hexLength ?? defaultHexLength);
    if (this.type === ZbRegexEnum.hexList) return hexList(this.config?.hexLength ?? defaultHexLength);
    if (this.type === ZbRegexEnum.decimal) return decimal(this.config?.decimalMin ?? TEN, this.config?.decimalMax ?? defaultMinLength);
    if (this.type === ZbRegexEnum.numberRange) return numbersRange(this.config?.startRange ?? defaultMinLength, this.config?.endRange ?? TEN);
    return this.config?.custom ?? '';
  }
  /**
   * @description
   * Method that validates if the type of expression is composite
   * @private
   */
  get validRegexType() {
    return [ZbRegexEnum.latitude, ZbRegexEnum.longitude, ZbRegexEnum.mail, ZbRegexEnum.decimal, ZbRegexEnum.hex, ZbRegexEnum.hexList, ZbRegexEnum.weakPassword, ZbRegexEnum.securePassword].some(rexType => rexType === this.type);
  }
  /**
   * @description
   * Method that returns the final regular expression
   */
  get getExpression() {
    const expression = this.processRegexType + (this.config?.extra ?? '');
    if (this.type === ZbRegexEnum.custom) return expression;
    if (this.validRegexType) return `${this.startOfChain}${expression}${this.endOfChain[dollarSimbolIndex]}`;
    if (!this.config) return `${this.startOfChain}[${expression}]${this.endOfChain}`;
    const {
      startWitch,
      endWitch
    } = this.config;
    if (startWitch && !endWitch) {
      return `${this.startOfChain}[${startWitch}]([${expression}])${this.endOfChain}`;
    }
    if (endWitch && !startWitch) {
      return `${this.startOfChain}([${expression}]*[${endWitch}]*)${this.endOfChain}`;
    }
    if (endWitch && startWitch) {
      return `${this.startOfChain}[${startWitch}]([${expression}]*[${endWitch}])${this.endOfChain}`;
    }
    return `${this.startOfChain}[${expression}]${this.endOfChain}`;
  }
}

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
* Project:      Zibanu Angular
* Developed by: Mario Cerón Charry
* Date:         15/08/23 - 18:30
* Module name:  Zibanu Angular
* File name:    index.ts
* IDE:          WebStorm
*/

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/**
 * ZbLib module definition.
 * Use NgxZibanuLibModule.forRoot( {...} )
 */
class NgxZibanuLibModule {
  static forRoot(zbLibConfig) {
    return {
      ngModule: NgxZibanuLibModule,
      providers: [ZbHttpClient, {
        provide: ZbLibConfigService,
        useValue: zbLibConfig
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: ZbHttpInterceptor,
        multi: true
      }]
    };
  }
  static {
    this.ɵfac = function NgxZibanuLibModule_Factory(t) {
      return new (t || NgxZibanuLibModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxZibanuLibModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxZibanuLibModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: []
    }]
  }], null, null);
})();

/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2023. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2023. Todos los derechos reservados.
 */
/*
 * Public API Surface of ngx-zibanu-lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxZibanuLibModule, TEN, ZbHttpClient, ZbHttpInterceptor, ZbLibConfigService, ZbRegexClass, ZbRegexEnum, ZbSecureStorage, ZbSecureStorageService, ZbStorageKeysEnum, alphabetic, alphanumeric, alphanumericUppercase, decimal, defaultCoordsDecimal, defaultHexLength, defaultMaxLength, defaultMinLength, dollarSimbolIndex, hex, hexList, latitude, longitude, lowercaseAlphabetic, mail, numbers, numbersRange, numeric, paragraph, securePassword, space, special, specialChars, uppercaseAlphabetic, weakPassword };
