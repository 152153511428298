<mkad-wrapper-dialog
  iconLeft="user-gear"
  footerAlign="between"
  [header]="'app.shared.userProfile.title' | translate"
  (clickClose)="onClickClose(null)">
  @if (userForm) {
    <form body class="form_userProfile" [formGroup]="userForm">
      <nb-tabset>
        <nb-tab tabTitle="{{'app.shared.userProfile.tabs.stepProfile' | translate}}"
                [tabIcon]="{icon:'user', pack:'far'}" responsive>
          <div class="grid-col gap-2">
            <section>
              <div class="flex flex-row flex-wrap">
                <div class="flex align-items-center justify-content-center m-2">
                  <div class="avatar-container">
                    @if (avatar) {
                      <div class="image-container"
                           [ngClass]="{'box-shadow-avatar': !!avatar}"
                           [ngStyle]="{backgroundImage: 'url(' + avatar + ')'}">
                      </div>
                    } @else {
                      <div class="image-container avatar-placeholder">
                      </div>
                    }
                    <label for="avatar">
                      <div class="icon-wrapper">
                        <input type="file" id="avatar" name="avatar" formControlName="avatar" accept="image/*"
                               (change)="onChangeFile($event)"/>
                        <nb-icon class="icon" icon="pencil" pack="far"></nb-icon>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="flex align-items-center justify-content-center m-2">
                  <div class="flex flex-column">
                    <div class="flex align-items-center ml-2">
                      <label><small>{{ 'app.shared.userProfile.field.avatar.label'|translate }}</small><sup>
                        <nb-icon nbSuffix icon="circle-exclamation" pack="far"
                                 nbTooltip="{{'app.shared.userProfile.field.avatar.tooltips'|translate}}"></nb-icon>
                      </sup></label></div>
                    <div class="flex align-items-center justify-content-center ml-2">
                      <label>{{ this.userData?.first_name }} {{ this.userData?.last_name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <label for="name">{{ 'app.shared.userProfile.field.name.label' | translate }}<sup>
                <nb-icon nbSuffix icon="circle-exclamation"
                         nbTooltip="{{'app.shared.userProfile.field.name.tooltip'|translate}}"/>
              </sup></label>
              <nb-form-field>
                <input fullWidth nbInput fieldSize="small" formControlName="name" id="name"
                       placeholder="{{'app.shared.userProfile.field.name.placeholder' | translate}}"
                       nbTooltip="{{'app.shared.userProfile.field.name.tooltip'|translate}}"
                       [status]="hasTouchedField('name') ?
                 (userForm.controls['name'].errors === null? 'success': 'danger') : 'basic'">
                <nb-icon nbSuffix icon="id-card" pack="far"></nb-icon>
              </nb-form-field>
              @if (hasTouchedField('name') && hasErrorField('name')) {
                <label class="label_error">
                  {{ 'app.shared.userProfile.field.name.error.invalid' | translate }}
                </label>
              }

            </section>
            <section>
              <label for="lastname">{{ 'app.shared.userProfile.field.lastname.label' | translate }}<sup>
                <nb-icon nbSuffix icon="circle-exclamation" pack="far"
                         nbTooltip="{{'app.shared.userProfile.field.lastname.tooltip'|translate}}"></nb-icon>
              </sup></label>
              <nb-form-field>
                <input fullWidth nbInput fieldSize="small" formControlName="lastname" id="lastname"
                       placeholder="{{'app.shared.userProfile.field.lastname.placeholder' | translate}}"
                       nbTooltip="{{'app.shared.userProfile.field.lastname.tooltip'|translate}}"
                       [status]="hasTouchedField('lastname') ?
                 (userForm.controls['lastname'].errors === null? 'success': 'danger') : 'basic'">
                <nb-icon nbSuffix icon="id-card" pack="far"></nb-icon>
              </nb-form-field>
              @if (hasTouchedField('lastname') && hasErrorField('lastname')) {
                <label class="label_error">
                  {{ 'app.shared.userProfile.field.lastname.error.invalid' | translate }}
                </label>
              }
            </section>
            <section>
              <label for="email">{{ 'app.shared.userProfile.field.email.label' | translate }}
                <sup>
                  <nb-icon nbSuffix icon="circle-exclamation" pack="far"
                           nbTooltip="{{'app.shared.userProfile.field.email.tooltip'|translate}}"></nb-icon>
                </sup>
              </label>
              <nb-form-field>
                <input fullWidth nbInput fieldSize="small" formControlName="email" id="email"
                       placeholder="{{'app.shared.userProfile.field.email.placeholder' | translate}}" [disabled]="true"
                       [nbTooltip]="userForm.controls['email'].value">
                <nb-icon nbSuffix icon="envelope" pack="far"/>
              </nb-form-field>
            </section>
          </div>
        </nb-tab>
        <nb-tab tabTitle="{{'app.shared.userProfile.tabs.stepPreference' | translate}}"
                [tabIcon]="{icon:'folder-gear', pack:'far'}" responsive>
          <div class="grid-col gap-2">
            <section>
              <label for="timeZone">{{ 'app.shared.userProfile.field.timeZone.label' | translate }}<sup>
                <nb-icon nbSuffix icon="circle-exclamation" pack="far"
                         nbTooltip="{{'app.shared.userProfile.field.timeZone.tooltip'|translate}}"></nb-icon>
              </sup></label>
              <nb-form-field>
                <nb-select fullWidth placeholder="{{'app.shared.userProfile.field.timeZone.placeholder' | translate}}"
                           size="small" formControlName="timeZone" id="timeZone"
                           nbTooltip="{{'app.shared.userProfile.field.timeZone.tooltip'|translate}}"
                           [status]="hasTouchedField('timeZone') ?
                         (userForm.controls['timeZone'].errors === null? 'success': 'danger') : 'basic'">
                  <nb-option *ngFor="let timeZone of timeZoneList" [value]="timeZone">{{ timeZone }}</nb-option>
                </nb-select>
              </nb-form-field>
            </section>
            <section>
              <label for="message">{{ 'app.shared.userProfile.field.message.label'|translate }}
                <sup>
                  <nb-icon nbSuffix icon="circle-exclamation" pack="far"
                           nbTooltip="{{'app.shared.userProfile.field.message.tooltip'|translate}}"/>
                </sup>
              </label>
              <nb-select fullWidth
                         placeholder="{{'app.shared.userProfile.field.message.placeholder'|translate}}"
                         nbTooltip="{{'app.shared.userProfile.field.message.tooltip'|translate}}"
                         size="small" formControlName="message" id="message"
                         [status]="hasTouchedField('message') ?
                         (userForm.controls['message'].errors === null? 'success': 'danger') : 'basic'">
                <nb-option *ngFor="let message of messageTimeOut;" [value]="message">{{ message }}</nb-option>
              </nb-select>
            </section>
            <section class="grid">
              <div class="col-6">
                <label for="language">{{ 'app.shared.userProfile.field.language.label' | translate }}
                  <sup>
                    <nb-icon icon="circle-exclamation"
                             nbTooltip="{{'app.shared.userProfile.field.language.tooltip'|translate}}"/>
                  </sup>
                </label>
                <nb-select fullWidth
                           placeholder="{{'app.shared.userProfile.field.language.placeholder'|translate}}"
                           nbTooltip="{{'app.shared.userProfile.field.language.tooltip'|translate}}"
                           size="small" formControlName="language" id="language"
                           [status]="hasTouchedField('language') ?
                         (userForm.controls['language'].errors === null? 'success': 'danger') : 'basic'">
                  <nb-option *ngFor="let language of languageList" [value]="language.id">{{ language.name }}</nb-option>
                </nb-select>
              </div>
              <div class="col-6">
                <label for="theme">
                  {{ 'app.shared.userProfile.field.theme.label' | translate }}
                  <sup>
                    <nb-icon nbSuffix icon="circle-exclamation" pack="far"
                             nbTooltip="{{'app.shared.userProfile.field.theme.tooltip'|translate}}"/>
                  </sup>
                </label>
                <nb-select fullWidth
                           placeholder="{{'app.shared.userProfile.field.theme.placeholder' | translate}}"
                           nbTooltip="{{'app.shared.userProfile.field.theme.tooltip'|translate}}"
                           size="small" formControlName="theme" id="theme">
                  @for (theme of themeList; track theme.id) {
                    <nb-option [value]="theme.id">{{ theme.name }}</nb-option>
                  }
                </nb-select>
              </div>
            </section>
            <section class="gap-2">
              <nb-toggle labelPosition="start" class="mkad-toggle-split" id="keepLogin" status="success"
                         formControlName="multipleSession" nbTooltipPlacement="right"
                         nbTooltip="{{'app.shared.userProfile.field.multipleSession.tooltips' | translate}}">
                {{ 'app.shared.userProfile.field.multipleSession.label'| translate }}
              </nb-toggle>
              <nb-toggle labelPosition="start" class="mt-2 mkad-toggle-split" id="multipleSession" status="success"
                         formControlName="keepLogin" nbTooltipPlacement="right"
                         nbTooltip="{{'app.shared.userProfile.field.keepLogin.tooltips' | translate}}">
                {{ 'app.shared.userProfile.field.keepLogin.label'| translate }}
              </nb-toggle>
              <nb-toggle labelPosition="start" class="mt-2 mkad-toggle-split" id="securePassword" status="success"
                         formControlName="securePassword" nbTooltipPlacement="right"
                         nbTooltip="{{'app.shared.userProfile.field.securePassword.tooltips'|translate}}">
                {{ 'app.shared.userProfile.field.securePassword.label'|translate }}
              </nb-toggle>
            </section>
          </div>
        </nb-tab>
      </nb-tabset>
    </form>
  }
  <ng-container footer>
    <button nbButton outline size="small" status="danger" type="button" (click)="onClickClose(null)"
            nbTooltip="{{ 'app.shared.userProfile.button.cancel.tooltip' | translate }}">
      {{ 'app.shared.userProfile.button.cancel.label' | translate }}
    </button>
    <button nbButton outline size="small" status="success" type="submit" (click)="onClickSubmit()"
            [disabled]="!isFormValid()" nbTooltip="{{ 'app.shared.userProfile.button.save.tooltip' | translate }}">
      {{ 'app.shared.userProfile.button.save.label' | translate }}
    </button>
  </ng-container>
</mkad-wrapper-dialog>
