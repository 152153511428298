import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CdkListbox, CdkOption} from '@angular/cdk/listbox';
import {NgForOf, NgIf} from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MkadSummaryListInterface} from '@shared/interface';


export type ItemListInterface = MkadSummaryListInterface;

@Component({
  selector: 'mkad-list-check',
  standalone: true,
  imports: [
    CdkListbox,
    CdkOption,
    NgForOf,
    ReactiveFormsModule,
    NgIf,
  ],
  template: `
    <article class="list__check__wrap w-full">
      <ul *ngIf="control" cdkListbox [formControl]="control"
          class="px-1 py-1 m-0 list-none w-full">
        @for (item of list; track item.id) {
          <li [cdkOption]="item" class="list__check__item cursor-pointer outline-none"
              (click)="itemSelected.emit(item)">
            {{ item.description }}
          </li>
        }
      </ul>
    </article>
  `,
  styleUrl: './list-check.component.scss',
})
export class ListCheckComponent {
  @Input({required: true}) list: ItemListInterface[] = [];
  @Input({required: false}) control?: FormControl;
  @Output() itemSelected = new EventEmitter<ItemListInterface>();

}
