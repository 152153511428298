import {Component, Input} from '@angular/core';
import {NbIconModule} from '@nebular/theme';

@Component({
  selector: 'mkad-label-icon',
  template: `
    @if (left) {
      <nb-icon [icon]="left" [status]="mkadStatusIcon" class="text-sm mr-1"/>
    }
    {{ text }}
    @if (right) {
      <nb-icon [icon]="right" [status]="mkadStatusIcon" class="text-sm ml-1"/>
    }
  `,
  standalone: true,
  imports: [NbIconModule],
})
export class MkadLabelIconComponent {
  @Input() left?: string;
  @Input() right?: string;
  @Input() text?: string;
  @Input() mkadStatusIcon = 'control';
}
