import {ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {Map, LatLng, LatLngBounds, Layer, LeafletMouseEvent, MapOptions, marker, tileLayer} from 'leaflet';
import {NbButtonModule, NbCardModule, NbDialogRef, NbTooltipModule} from '@nebular/theme';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MkadAlertComponent, MkadLabelIconComponent} from '../../../atoms';
import {TranslateModule} from '@ngx-translate/core';
import {DialogContextMapInterface} from '@shared/interface';
import {
  DEFAULT_MAP_BOUNDS,
  DEFAULT_MAP_LOCATION,
  MAP_ATTRIBUTION,
  MAP_URL_TEMPLATE,
  MAP_ZOOM,
  MAP_ZOOM_MAX,
  MAX_LNG_EAST,
} from '@shared/constants';

/**
 * Component that render a map for markers controls
 * @selector mkad-map-markers
 * @standalone True
 */
@Component({
  selector: 'mkad-map-markers',
  templateUrl: './map-markers.component.html',
  standalone: true,
  imports: [NbCardModule, LeafletModule, MkadLabelIconComponent, NbButtonModule, TranslateModule, NbTooltipModule, MkadAlertComponent],
})
export class MapMarkersComponent implements OnDestroy {
  @Input({required: false}) dialogContext!: DialogContextMapInterface;
  centerMap = DEFAULT_MAP_LOCATION;
  zoomMap = MAP_ZOOM;
  leafletMaxBounds: LatLngBounds = DEFAULT_MAP_BOUNDS;
  configMap: MapOptions = {
    minZoom: 1,
    maxBoundsViscosity: 1.0,
    layers: [
      tileLayer(MAP_URL_TEMPLATE, {
        maxZoom: MAP_ZOOM_MAX,
        attribution: MAP_ATTRIBUTION,
        noWrap: false,
      }),
    ],
  };
  layers: Layer[] = [];
  mapInstance?: Map;

  /**
   * @description
   * Constructor class
   * @param dialogRef NbDialogRef dependency injection
   * @param cdf ChangeDetectorRef dependency injection
   */
  constructor(readonly dialogRef: NbDialogRef<MapMarkersComponent>,
              private readonly cdf: ChangeDetectorRef) {
  }

  /**
   * @description
   * Method that implements the OnDestroy life cicle
   */
  ngOnDestroy() {
    this.layers = [];
    this.mapInstance = undefined;
  }

  /**
   * @description
   * Method that replace the marker selected
   * @param newMarker point of the market
   * @private
   */
  private replaceLayer(newMarker: LatLng): void {
    this.layers = [
      marker(newMarker),
    ];
  }

  /**
   * @description
   * Method that responds to the initialisation of the map
   * @param instance instance of the map created
   */
  onReadyMap(instance: Map): void {
    this.mapInstance = instance;
    if (this.dialogContext) {
      this.configMap.zoom = this.dialogContext.zoom;
      this.configMap.center = this.dialogContext.center;
      this.centerMap = this.dialogContext.center;
      this.zoomMap = this.dialogContext.zoom;
      this.replaceLayer(this.dialogContext.center);
    } else {
      this.replaceLayer(DEFAULT_MAP_LOCATION);
    }
    setTimeout(() => {
      this.mapInstance?.invalidateSize();
    }, MAX_LNG_EAST);
    this.cdf.detectChanges();
  }

  /**
   * @description
   * Method that response to the click on map
   * @param event click instance
   */
  onClickLeaflet({latlng}: LeafletMouseEvent) {
    this.replaceLayer(latlng);
    this.centerMap = latlng;
    if (this.mapInstance) this.mapInstance.invalidateSize();
  }

  /**
   * @description
   * Method that close the dialog component
   */
  onClickClose(): void {
    this.dialogRef.close();
  }

  /**
   * @description
   * Method that close the dialog component and return the mark selected
   */
  onClickSave(): void {
    this.dialogRef.close({
      zoom: this.zoomMap,
      center: this.centerMap,
    });
  }
}
