import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {NbButtonModule, NbCardModule, NbIconConfig, NbIconModule, NbSpinnerModule} from '@nebular/theme';
import {UserLevelEnum, ZbAuthCheckerService} from '@zibanu/auth';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PanelOptionInterface} from '@shared/interface';
import {MkadCheckLevelType} from '@shared/enums';
import {SharedModule} from '@shared/shared.module';
import {MkadBaseComponent} from '@shared/components';

const scrollDistance = 300;

@Component({
  selector: 'mkad-carousel',
  standalone: true,
  imports: [
    SharedModule,
    NbButtonModule,
    NbIconModule,
    NbCardModule,
    NbSpinnerModule,
  ],
  templateUrl: './mkad-carousel.component.html',
  styleUrl: './mkad-carousel.component.scss',
})
export class MkadCarouselComponent extends MkadBaseComponent implements OnInit, AfterViewChecked {
  @ViewChild('carouselWrap') carouselRef?: ElementRef<HTMLDivElement>;
  @Input() cardList: PanelOptionInterface[] = [];
  @Input() headerTitle: string = '';
  readonly levelType = MkadCheckLevelType;

  /**
   * @description
   * Constructor class
   * @param translateService TranslateService dependency injection
   * @param router Router instance
   * @param zbAuthChecker ZbAuthManagerService dependency injection
   * @param cdr ChangeDetectorRef dependency injection
   */
  constructor(
    protected readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly zbAuthChecker: ZbAuthCheckerService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(translateService);
  }

  /**
   * @description
   * Method that response to lifecycle OnInit
   */
  override ngOnInit() {
    super.ngOnInit();
    this.isLoading = false;
  }

  /**
   * @description
   * Method that response to lifecycle AfterViewChecked
   */
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /**
   * @description
   * Method that validates if it shows the side buttons
   */
  get showButtons(): boolean {
    if (!this.carouselRef) return true;
    const clientWidth = this.carouselRef.nativeElement.clientWidth;
    const scrollWidth = this.carouselRef.nativeElement.scrollWidth;
    return (scrollWidth > clientWidth);
  }

  /**
   * @description
   * Method that manually scrolls the scroll bar
   * @param direction
   * @private
   */
  private moveScroll(direction: 'right' | 'left') {
    if (!this.carouselRef) return;
    const rightDistance = this.carouselRef.nativeElement.scrollLeft - scrollDistance;
    const leftDistance = this.carouselRef.nativeElement.scrollLeft + scrollDistance;
    const distance = direction === 'right' ? leftDistance : rightDistance;
    this.carouselRef.nativeElement.scrollTo({
      left: distance,
      behavior: 'smooth',
    });
  }

  /**
   * @description
   * Method that returns the strongly typed icon
   */
  parseIcon(icon: string | NbIconConfig): string {
    return <string>icon;
  }

  /**
   * @description
   * Method that response to click on the card
   * @param {string} route
   */
  onClickCard(route: string): void {
    this.isLoading = true;
    this.zbAuthChecker.isGranted(UserLevelEnum.Guest).subscribe(
      {
        next: (valueGranted: boolean) => {
          if (valueGranted) {
            this.router.navigate(['settings/' + route]).then();
          } else {
            this.showError('No autorizado...');
          }
          this.isLoading = false;
        },
        error: (error) => this.handleErrorResponse(error),
      },
    );
  }

  /**
   * @description
   * Method that responds to click to scroll to the left
   */
  clickScrollLeft() {
    this.moveScroll('left');
  }

  /**
   * @description
   * Method that responds to click to scroll to the right
   */
  clickScrollRight() {
    this.moveScroll('right');
  }

}
