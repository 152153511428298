/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
* Project:      frontend
* Developed by: Jhoana Marcela Rodriguez Fernandez
* Date:         6/4/2024 - 11:00
* Module name:  frontend
* File name:    contacts.service.ts
* IDE:          WebStorm
*/
import {MkadBaseService, URLS_SERVICE} from '@shared/services';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export interface CountryDialCodeInterface {
  name: string,
  dial_code: string
}

@Injectable()
export class MkadCountryService extends MkadBaseService {
  readonly coreEndpoints = URLS_SERVICE.core;

  listDialCodes(): Observable<CountryDialCodeInterface[]> {
    return this.http.post<CountryDialCodeInterface[]>(this.coreEndpoints.base + this.coreEndpoints.dialCodes)
  }
}
