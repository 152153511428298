import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NbButtonModule, NbCardModule, NbIconModule, NbTooltipModule} from '@nebular/theme';

/**
 * Component in charge of rendering a dialog component
 * @standalone True
 * @selector mkad-base-dialog
 */
@Component({
  selector: 'mkad-wrapper-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbTooltipModule,
  ],
  encapsulation: ViewEncapsulation.None,
  template: `
    <nb-card [class]="'h-'+height+' max-h-full w-'+width+' max-w-full'">
      <nb-card-header class="py-2 px-3">
        <section class="w-full flex__vcenter justify-content-between gap-2">
          <div class="flex__vcenter">
            <nb-icon [icon]="iconLeft" class="w-auto text-lg" status="success"/>
            <span class="text-base"> {{ header }} </span>
          </div>
          <button nbButton ghost status="danger" size="small" (click)="clickClose.emit()"
                  [nbTooltip]="'app.shared.base.dialog.header.right' | translate">
            <nb-icon class="text-lg" [icon]="iconRight"/>
          </button>
        </section>
      </nb-card-header>
      <nb-card-body class="py-2 px-3">
        <ng-content select="[body]"/>
      </nb-card-body>
      <nb-card-footer [class]="'py-2 px-3 flex gap-2 justify-content-' + footerAlign">
        <ng-content select="[footer]"/>
      </nb-card-footer>
    </nb-card>
  `,
})
export class MkadWrapperDialogFormComponent {
  @Input({required: true}) iconLeft = '';
  @Input({required: true}) header = '';
  @Input({required: false}) iconRight = 'xmark';
  @Input({required: false}) width = '23rem';
  @Input({required: false}) height = 'auto';
  @Input({required: false}) footerAlign: 'center' | 'start' | 'end' | 'between' = 'end';
  @Output() clickClose = new EventEmitter<null>();
}
