import {Component, inject, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';
import {StatusDeviceService} from '../../../services';
import {NO_INDEX_FIND, STATUS_DEVICE_TIMEOUT} from '../../../constants';
import {MkadChartBarComponent} from '../../index';

@Component({
  selector: 'mkad-status-bar',
  standalone: true,
  imports: [NgIf, MkadChartBarComponent],
  template: `
    <mkad-chart-bar *ngIf="charData" class="w-full" [data]="charData" (clickEvent)="onClickBarChart($event)"/>
  `,
  providers: [StatusDeviceService],
})
export class MkadStatusDeviceComponent implements OnInit {
  private readonly statusDeviceService = inject(StatusDeviceService);
  intervalRef: number = NO_INDEX_FIND;
  charData: any | undefined;

  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  ngOnInit() {
    this.getStatusDeviceData();
    this.setupInterval();
  }

  /**
   * @description
   * Method starting interval
   */
  setupInterval(): void {
    const id = setInterval(() => {
      this.getStatusDeviceData();
    }, STATUS_DEVICE_TIMEOUT);
    this.intervalRef = Number(id);
  }

  /**
   * @description
   * Method that queries data via statusDeviceService
   */
  getStatusDeviceData(): void {
    this.statusDeviceService.getStatusDataChart().subscribe({
      next: (response) => {
        this.charData = response;
      },
      error: () => {
        clearInterval(this.intervalRef);
      },
    });
  }

  /**
   * @description
   * Method that responds to clickEvent of bar chart
   * @param e data ref in the bar chart
   */
  onClickBarChart(e: any): void {
    console.log('click en chart bar');
    console.log(e);
  }

}
