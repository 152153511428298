import {Component, Input} from '@angular/core';
import {NbCardModule, NbDialogRef} from '@nebular/theme';
import {MkadIconCloseComponent} from '../../index';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'mkad-terms-and-conditions',
  standalone: true,
  imports: [
    NbCardModule,
    MkadIconCloseComponent,
    TranslateModule,
  ],
  templateUrl: './legal-content-dialog.component.html',
})
export class LegalContentDialogComponent {
  @Input({required: true}) data: string | null = null;

  /**
   * @description
   * Constructor class
   * @param dialogRef
   */
  constructor(private readonly dialogRef: NbDialogRef<LegalContentDialogComponent>) {
  }

  /**
   * @description
   * Method that closes the modal
   */
  onClickClose(): void {
    this.dialogRef.close();
  }
}
