/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         20/12/23 - 3:34 PM
 * Module name:  url-service.constants.ts
 * File name:    url-service.constants.ts
 * IDE:          WebStorm
 */

interface BasicEndpointUrlInterface {
  base: string;
  data: string;
}

interface UpdateEndpointUrlInterface {
  base: string;
  update: string;
  get?: string;
}

interface CrudEndpointUrlInterface {
  base: string;
  list: string;
  get: string;
  create: string;
  update: string;
  resume: string;
  delete: string;
}

interface DeviceModelEndpointsInterface extends CrudEndpointUrlInterface {
  getVariablesByModel: string;
  validateFormula: string;
}

interface ParametersEndpointUrlInterface extends CrudEndpointUrlInterface {
  resumeParent: string;
}

interface CustomerEndpointUrlInterface extends CrudEndpointUrlInterface {
  resumeContact: string;
}

interface DeviceEndpointUrlInterface extends CrudEndpointUrlInterface {
  varLimit: string;
}


interface LicensesEndpointUrlInterface {
  base: string;
  resume: string;
  customerUser: string;
  customerDevice: string;
}

interface CoreEndpointUrlInterface {
  base: string;
  about: BasicEndpointUrlInterface;
  status: BasicEndpointUrlInterface;
  lang: string;
  timezone: string;
  dialCodes: string;
  validateFormula: string;
}

interface MeerkadEndpointInterface {
  customer: CustomerEndpointUrlInterface;
  contract: CrudEndpointUrlInterface;
  licenses: LicensesEndpointUrlInterface;
  contacts: CrudEndpointUrlInterface;
  group: CrudEndpointUrlInterface;
  user: CrudEndpointUrlInterface;
  location: CrudEndpointUrlInterface;
  device: DeviceEndpointUrlInterface;
}

interface AuthEndpointUrlInterface {
  base: string;
  profile: UpdateEndpointUrlInterface;
  settings: UpdateEndpointUrlInterface;
  permission: UpdateEndpointUrlInterface;
}

interface EndpointUrlInterface {
  core: CoreEndpointUrlInterface;
  meerkad: MeerkadEndpointInterface;
  system: {
    sensorUtils: BasicEndpointUrlInterface;
    sensors: CrudEndpointUrlInterface;
    parameters: ParametersEndpointUrlInterface;
    commands: CrudEndpointUrlInterface;
    icons: CrudEndpointUrlInterface;
    deviceModel: DeviceModelEndpointsInterface;
    deviceSensorUtils: BasicEndpointUrlInterface;
    devices: CrudEndpointUrlInterface;
  };
  devices: {
    triggers: CrudEndpointUrlInterface;
    enumTrigger: BasicEndpointUrlInterface;
    deviceCustomization: CrudEndpointUrlInterface;
  };
  auth: AuthEndpointUrlInterface;
}

/**
 * @naming
 * base: for prefix of the feature or module
 * get: for query a specific item
 * list: for query the list
 * summary: for query the list specify
 * update: for update a specific item
 * create: for create an item
 * delete: for delete a specific item
 * toggle: for delete a specific item
 */
export const URLS_SERVICE: EndpointUrlInterface = {
  core: {
    base: 'core/',
    about: {
      base: 'about/',
      data: 'data/',
    },
    status: {
      base: 'status/',
      data: 'data/',
    },
    lang: 'language/',
    timezone: 'timezone/',
    dialCodes: 'dialcodes/',
    validateFormula: 'validate_formula/',
  },
  meerkad: {
    customer: {
      base: 'customer/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
      resume: 'summary/',
      resumeContact: 'summary-contact/',
      get: 'get/',
    },
    contract: {
      base: 'contract/',
      list: 'list/',
      resume: 'summary/',
      delete: 'delete/',
      create: 'create/',
      get: 'get/',
      update: 'update/',
    },
    licenses: {
      base: 'license/',
      resume: 'summary/',
      customerUser: 'user',
      customerDevice: 'device',
    },
    contacts: {
      base: 'contact/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
      get: 'get/',
      resume: '',
    },
    group: {
      base: 'group/',
      resume: 'summary/',
      get: 'get/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
    },
    user: {
      base: 'user/',
      resume: 'summary/',
      get: 'get/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
    },
    location: {
      base: 'location/',
      resume: 'summary/',
      get: 'get/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
    },
    device: {
      base: 'device/',
      resume: 'summary/',
      get: 'get/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
      varLimit: 'summary_var_limit/',
    },
  },
  system: {
    sensorUtils: {
      base: 'enum/',
      data: 'sensor/',
    },
    sensors: {
      base: 'sensor/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
    },
    parameters: {
      base: 'parameter/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
      resumeParent: 'summary-parent/',
    },
    commands: {
      base: 'command/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
    },
    icons: {
      base: 'icon/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
    },
    deviceModel: {
      base: 'device_model/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
      getVariablesByModel: 'portvars/',
      validateFormula: 'validate_formula'
    },

    deviceSensorUtils: {
      base: 'enum/',
      data: 'device_model/',
    },
    devices: {
      base: 'device/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
    },
  },
  devices: {
    deviceCustomization: {
      base: 'device/',
      get: 'get/',
      list: 'list/',
      create: 'create/',
      update: 'update/',
      delete: 'delete/',
      resume: 'summary/',
    },
    triggers: {
      base: 'trigger/',
      resume: 'summary/',
      get: 'get/',
      list: 'list/',
      delete: 'delete/',
      create: 'create/',
      update: 'update/',
    },
    enumTrigger: {
      base: 'enum/',
      data: 'trigger/',
    },
  },
  auth: {
    base: 'auth/',
    profile: {
      base: 'user/',
      get: 'profile/',
      update: 'update/',
    },
    settings: {
      base: 'app-settings/',
      update: 'update/',
    },
    permission: {
      base: 'permission/',
      update: 'update/',
      get: 'list/',
    },
  },
};
