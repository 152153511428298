<section class="w-full h-full flex flex-row gap-1 justify-content-between">
  <div class="flex flex-row align-items-center max-w-1/4">
    <nb-icon tabindex="1" mkadFaSpin pack="far" icon="bars" class="cursor-pointer"
             (click)="clickToggleIcon.emit()"
             [nbTooltip]="'app.header.menuIcon.tooltip' | translate"
             [attr.aria-label]="'app.header.menuIcon.aria' | translate"/>
    <a routerLink="main" class="h-full">
      <img class="company__logo" priority [ngSrc]="logoUrl"
           [alt]="'app.header.logo.alt' | translate"
           height="127" width="340">
    </a>
  </div>
  <mkad-status-bar class="flex gap-1 w-3"/>
  <div class="flex flex-row">
    <nb-user cdkOverlayOrigin
             #overlayDispatch="cdkOverlayOrigin"
             [class]="'label__left cursor-pointer'"
             [ngClass]="{
                    'hidden': !isActive,
                    'logged': showAvatarAlert ? false : perpetualSession,
                    'loggedWarning': showAvatarAlert
                 }"
             shape="round"
             badgeStatus="success"
             badgePosition="top left"
             [badgeText]="isActive ? 'o' : ''"
             [picture]="avatarPicture()"
             [showInitials]="!avatarUrl"
             [nbTooltip]="isActive ? '' : ('app.header.user.tooltip' | translate)"
             [nbTooltipDisabled]="isActive"
             [name]="isActive ? name : ('app.header.user.guest' | translate)"
             (click)="onClickUserAvatar()"/>
    @if (!isActive) {
      <nb-user class="label__left cursor-pointer"
               shape="round"
               [showInitials]="true"
               [nbTooltip]="'app.header.user.tooltip' | translate"
               [name]="'app.header.user.guest' | translate"
               (click)="clickAvatar.emit()"/>
    }
    <ng-template cdkConnectedOverlay cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
                 [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="overlayDispatch"
                 [cdkConnectedOverlayOpen]="showOverlay"
                 (detach)="showOverlay = false" (backdropClick)="showOverlay = false">
      <nb-card cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <mkad-user-overlay (clickOverlay)="onClickOverlay()"/>
      </nb-card>
    </ng-template>
  </div>
</section>
