import {Component, inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {
  ZbBaseComponent,
  ZbStatusEnum,
  ZbStatusType,
  ZbStatusValidExp,
  ZbToastContentInterface,
} from '@zibanu/ui';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {PreferencesService} from '@shared/services';
import {HttpErrorResponse} from '@angular/common/http';
import {SETTING_TOAST_TIMEOUT, THOUSAND} from '@shared/constants';
import {getToastConfig} from '@shared/utils';
import {NbDialogModule, NbDialogRef, NbToastrModule} from '@nebular/theme';
import {PlatformLocation} from '@angular/common';


/**
 * Master class providing standardised functionality for toastr messages, translations and modal.
 * @selector mkad-base
 * @standalone True
 * @extends ZbBaseComponent
 */
@Component({
  selector: 'mkad-base',
  standalone: true,
  imports: [TranslateModule, NbToastrModule, NbDialogModule],
  template: '',
})
export class MkadBaseComponent<ref = unknown> extends ZbBaseComponent implements OnInit, OnDestroy {
  protected readonly preferences = inject(PreferencesService);
  protected readonly platformLocation = inject(PlatformLocation);

  /**
   * @description
   * Constructor class
   * @param translate
   * @param dialogRef NbDialogRef dependency injection
   */
  constructor(protected override translate: TranslateService,
              @Optional() protected dialogRef?: NbDialogRef<ref>) {
    super(translate);
    if (this.dialogRef) {
      this.platformLocation.onPopState(() => this.onCloseDialog<null>(null));
    }
  }

  /**
   * @description
   * Method implemented by the OnInit life cycle
   */
  override ngOnInit() {
    super.ngOnInit();
  }

  /**
   * @description
   * Method implemented by the OnDestroy life cycle
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * @description
   * Method that returns the duration of the toast in milliseconds
   */
  get messages_timeout(): number {
    return Number(this.preferences.userSettings.messages_timeout ?? SETTING_TOAST_TIMEOUT) * THOUSAND;
  }

  /**
   * @description
   * Method that triggers the toast according to types (severity)
   * @param severity toast status
   * @param content message content
   * @protected
   */
  protected override showToast(severity: ZbStatusType, content: ZbToastContentInterface) {
    if (typeof content.message == 'string') {
      content.message = [content.message];
    }
    if (Object.keys(ZbStatusEnum).some(ZbStatusValidExp(severity))) {
      content.message.forEach(
        (message: string) => {
          this.toast.show(
            undefined,
            message,
            getToastConfig(
              severity,
              this.toastIconControl[severity],
              content.cssClass,
              this.messages_timeout),
          );
        },
      );
    } else {
      this.toast.control(
        content.title,
        content.message,
        getToastConfig(
          severity,
          this.toastIconControl[severity],
          content.cssClass,
          this.messages_timeout));
    }
  }

  /**
   * @description
   * Method that handles responses to failed requests to the back, stops the spiner and displays the error message
   * @param response
   */
  handleErrorResponse(response: HttpErrorResponse) {
    this.isLoading = false;
    this.showError(response.error ? response.error.errors : [response.error]);
  }

  /**
   * @description
   * Method that closes the dialog
   * @param returnValue value that will be returned when closing the dialog box
   * @protected
   */
  protected onCloseDialog<T>(returnValue: T): void {
    if (this.dialogRef) this.dialogRef.close(returnValue);
  }

}
