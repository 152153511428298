import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {NbMenuModule, NbTagModule} from '@nebular/theme';
import {AsyncPipe, DatePipe, NgIf} from '@angular/common';
import {MkadMenuManagerService, PreferencesService} from '@shared/services';
import {MENU_OVERLAY_TAG} from '@shared/constants';
import {UserLevelEnum} from '@zibanu/auth';

export const LEVEL_USER: Record<UserLevelEnum, string>  = {
  [UserLevelEnum.SuperUser]:'SuperUser',
  [UserLevelEnum.Staff]:'Staff',
  [UserLevelEnum.SuperAdmin]:'SuperAdmin',
  [UserLevelEnum.Admin]:'Admin',
  [UserLevelEnum.Supervisor]:'Supervisor',
  [UserLevelEnum.Operator]:'Operator',
  [UserLevelEnum.Guest]:'Guest',
}

export const LEVEL_CLASS: Record<UserLevelEnum, string>  = {
  [UserLevelEnum.SuperUser]:'super-user',
  [UserLevelEnum.Staff]:'staff',
  [UserLevelEnum.SuperAdmin]:'super-admin',
  [UserLevelEnum.Admin]:'admin',
  [UserLevelEnum.Supervisor]:'supervisor',
  [UserLevelEnum.Operator]:'ope',
  [UserLevelEnum.Guest]:'guest',
}

@Component({
  selector: 'mkad-user-overlay',
  standalone: true,
  template: `
    <div class="user__overlay flex flex-column align-items-center w-16rem h-auto p-3">
      <img *ngIf="avatarUrl" [src]="avatarUrl" class="border-circle" alt="user-logo-image" width="150" height="150">
        <nb-tag [class]="classLevel[level]" status="control" appearance="outline" text="{{ userLevel[level] }}"></nb-tag>
      <small class="m-0">{{ lastLogin | date:  'YYYY-MM-dd h:mm a' }}</small>
    </div>
    <nb-menu *ngIf="overlayMenu$$ | async as menu" [tag]="userOverlayTag" [items]="menu" (click)="onClickMenu()"/>
  `,
  imports: [NgIf, NbMenuModule, AsyncPipe, DatePipe, NbTagModule],
})
export class UserOverlayComponent implements OnInit {
  @Output() clickOverlay = new EventEmitter();
  private readonly mkadMenu = inject(MkadMenuManagerService);
  private readonly preferences = inject(PreferencesService);
  userOverlayTag = MENU_OVERLAY_TAG;
  userLevel=LEVEL_USER;
  classLevel=LEVEL_CLASS;
  overlayMenu$$ = this.mkadMenu.onChangeOverlayMenu();
  avatarUrl?: string;


  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  ngOnInit() {
    this.avatarUrl = this.preferences.avatar;
  }

  /**
   * @description
   * Method that response to click on menu
   */
  onClickMenu() {
    this.clickOverlay.emit();
  }

  /**
   * @description
   * Method that return the username
   */
  get username(): string {
    return this.preferences.username ?? '';
  }

  /**
   * @description
   * Method that return the last login
   */
  get lastLogin(): string {
    return this.preferences.lastlogin ?? '';
  }

  /**
   * @description
   * Method that return the level
   */
  get level(): UserLevelEnum {
    return this.preferences.level ?? '';
  }

  /**
   * @description
   * Method that return the user email
   */
  get userEmail(): string {
    return this.preferences.userSettings.email ?? '';
  }
}
