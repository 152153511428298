<nb-card class="relative h-90vh legal-content">
  <mkad-icon-close (clickClose)="onClickClose()"></mkad-icon-close>
  <nb-card-body class="w-40rem overflow-y-scroll">
    @if (data) {
      <div [innerHTML]="data">
      </div>
    } @else {
      <span>
        {{'app.main.about.policies.noData' | translate}}
      </span>
    }
  </nb-card-body>
</nb-card>
