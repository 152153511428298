/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         20/12/23 - 12:12 PM
 * Module name:  base.service.ts
 * File name:    base.service.ts
 * IDE:          WebStorm
 */


import {inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ZbHttpClient} from '@zibanu/lib';
import {TranslateService} from '@ngx-translate/core';
import {NbToastrService} from '@nebular/theme';
import {URLS_SERVICE} from '@shared/services/url-service.const';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class MkadBaseService {
  private api = environment['apiUrl'];
  protected readonly http = inject(ZbHttpClient);
  protected readonly translate = inject(TranslateService);
  protected readonly toast = inject(NbToastrService);
  readonly baseCore = URLS_SERVICE.core.base;

  public getUrl(endpoint: string): string {
    return this.api + (endpoint.endsWith('/') ? endpoint : endpoint + '/');
  }

  showError(msm: string | string[]) {
    if (typeof msm === 'string') {
      this.toast.danger(msm)
    } else {
      msm.forEach((message) => {
        this.toast.danger(message)
      })
    }
  }

  showSuccess(msm: string | string[]) {
    if (typeof msm === 'string') {
      this.toast.success(msm)
    } else {
      msm.forEach((message) => {
        this.toast.success(message)
      })
    }
  }

}
