import { Injectable } from '@angular/core';
import {MkadBaseService, URLS_SERVICE} from '@shared/services';
import {map, Observable} from 'rxjs';
import {
  CommandsFormInterface,
  CommandsListInterface, SummaryCommandInterface
} from '@shared/interface/settings/system/commands/commands.interface';
import {HttpResponse} from '@angular/common/http';
import {HTTP_CODE_SUCCESS} from '@shared/constants';


@Injectable()
export class MkadCommandsService extends MkadBaseService {
  readonly commands = URLS_SERVICE.system.commands;

  /**
   * @description
   * Method that queries the nested summary of commands
   */
  summaryCommand(): Observable<SummaryCommandInterface[]> {
    return this.http.post<SummaryCommandInterface[]>(this.baseCore + this.commands.base + this.commands.resume);
  }

  /**
   * @description
   * Method that queries the nested list of commands
   */
  list(): Observable<CommandsListInterface[]> {
    return this.http.post<CommandsListInterface[]>(this.baseCore + this.commands.base + this.commands.list);
  }

  /**
   * @description
   * Method that sends a request to create a commands
   * @param payload payload for the endpoint
   */
  create(payload: CommandsFormInterface): Observable<CommandsListInterface> {
    return this.http.post<CommandsListInterface>(this.baseCore + this.commands.base + this.commands.create, payload);
  }

  /**
   * @description
   * Method that sends a request to update a commands
   * @param payload payload for the endpoint
   */
  update(payload: CommandsFormInterface): Observable<CommandsFormInterface> {
    return this.http.post<CommandsFormInterface>(this.baseCore + this.commands.base + this.commands.update, payload);
  }

  /**
   * @description
   * Method that sends a request to obtain the id of a command
   * @param param id of the command
   */
  get(param: number): Observable<CommandsFormInterface> {
    return this.http.post<CommandsFormInterface>(this.baseCore + this.commands.base + this.commands.get, {id: param});
  }

  /**
   * @description
   * Method that delete a specific command
   * @param param id of the command
   */
  delete(param: number): Observable<boolean> {
    return this.http.post<HttpResponse<boolean>>(this.baseCore + this.commands.base + this.commands.delete, {id: param}).pipe(
      map((prevResponse) => {
          if (!prevResponse) return true;
          return prevResponse.status === HTTP_CODE_SUCCESS;
        },
      ));
  }
}
