import {
  Component, effect,
  EventEmitter, input,
  Optional,
  Output,
} from '@angular/core';
import {LatLng, Layer, LeafletMouseEvent, Map, MapOptions, tileLayer} from 'leaflet';
import {NbDialogRef} from '@nebular/theme';
import {DEFAULT_MAP_LOCATION, MAP_ATTRIBUTION, MAP_URL_TEMPLATE, MAP_ZOOM, MAP_ZOOM_MAX} from '@shared/constants';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

/**
 * Component that renders a base map with the location in a marker
 * @selector mkad-map-basic
 * @standalone True
 */
@Component({
  selector: 'mkad-map-basic',
  standalone: true,
  imports: [LeafletModule],
  template: `
    <div class="size__full shadow-3 border-round-lg"
         leaflet
         [leafletOptions]="configMap"
         [leafletLayers]="layers()"
         [leafletCenter]="center()"
         [leafletZoom]="zoom()"
         (leafletMapReady)="readyMap($event)"
         (leafletClick)="clickMap.emit($event)">
    </div>
  `,
})
export class MkadMapBasicComponent {
  zoom = input<number>(MAP_ZOOM);
  center = input<LatLng>(DEFAULT_MAP_LOCATION);
  layers = input<Layer[]>([]);
  readonly = input<boolean>(false);
  @Output() clickMap = new EventEmitter<LeafletMouseEvent>();
  configMap: MapOptions = {
    center: this.center(),
    zoom: this.zoom(),
    layers: [
      tileLayer(MAP_URL_TEMPLATE, {
        maxZoom: MAP_ZOOM_MAX,
        attribution: MAP_ATTRIBUTION,
      }),
    ],
  };
  private mapInstance?: Map;

  /**
   * @description
   * Constructor class
   * @param dialogRef @optional NbDialogRef dependency injection
   */
  constructor(@Optional() readonly dialogRef: NbDialogRef<MkadMapBasicComponent>) {
    effect(() => {
      console.log('validando');
      this.mapInstance?.invalidateSize();
    });
  }

  /**
   * @description
   * Method to be executed when the map is ready
   * @param map map instance
   */
  readyMap(map: Map) {
    this.mapInstance = map;
    if (this.readonly()) {
      this.mapInstance.dragging.disable();
      this.mapInstance.touchZoom.disable();
      this.mapInstance.doubleClickZoom.disable();
      this.mapInstance.scrollWheelZoom.disable();
      this.mapInstance.boxZoom.disable();
      this.mapInstance.keyboard.disable();
      if (this.mapInstance.tap) this.mapInstance.tap.disable();
      this.mapInstance.zoomControl.remove();
    }
  }
}
