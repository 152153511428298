import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NbButtonModule, NbIconModule, NbSpinnerModule, NbThemeService, NbTooltipModule} from '@nebular/theme';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {headerTitle} from '@shared/utils';
import {Title} from '@angular/platform-browser';
import {TableThemeEnum} from '@shared/components/table';

export const MkadTableTheme: Record<string, TableThemeEnum> = {
  'corporate': TableThemeEnum.light,
  'default': TableThemeEnum.light,
  'dark': TableThemeEnum.dark,
  'cosmic': TableThemeEnum.dark,
};

/**
 * Component in charge of rendering a list view
 * @standalone True
 * @extends
 */
@Component({
  selector: 'mkad-wrapper-list',
  standalone: true,
  imports: [TranslateModule, NbButtonModule, NbTooltipModule, NbIconModule, NbSpinnerModule],
  template: `
    <div [nbSpinner]="loading" class="flex flex-column gap-2">
      <section class="base__list__header h-min align-items-center border-round-md">
        <div class="w-fit pl-3">
          <div class="flex gap-2 align-items-center">
            <button nbButton ghost status="danger" class="h-min" size="small"
                    [nbTooltip]="'app.shared.base.list.button.back' | translate" (click)="handleClickGoBack()">
              <nb-icon icon="arrow-left-long-to-line" class="d-grid place-center text-lg"/>
            </button>
            <h6 class="m-0">{{ headerTitle }} </h6>
          </div>
        </div>
        <div class="w-fit center__container">
          <ng-content select="[headerCenter]"/>
        </div>
        <div class="w-fit pr-3 buttons__container flex gap-2">
          <ng-content select="[secondaryButton]"/>
          @if (!hiddenCreate) {
            <button nbButton outline status="success" class="h-min" size="small" (click)="clickCreate.emit()"
                    [disabled]="disableCreate">
              <nb-icon icon="add" class="d-grid place-center"/>
              {{ 'app.shared.base.list.button.create'| translate }}
            </button>
          }
        </div>
      </section>
      <section>
        <ng-content select="[body]"/>
      </section>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class MkadWrapperListComponent implements OnInit, OnDestroy {
  @Input({required: true}) headerTitle = '';
  @Input({required: false}) loading = false;
  @Input({required: false}) disableCreate = false;
  @Input({required: false}) hiddenCreate = false;
  @Output() clickGLeftButton = new EventEmitter<null>();
  @Output() clickCreate = new EventEmitter<null>();
  @Output() changeTheme = new EventEmitter<TableThemeEnum>();
  @Output() changeLang = new EventEmitter<null>();
  private changeLangSubscriber: Subscription;

  /**
   * Constructor class
   * @param themeService NbThemeService dependency injection
   * @param location Location dependency injection
   * @param titleService Title dependency injection
   * @param translate TranslateService dependency injection
   */
  constructor(
    private readonly themeService: NbThemeService,
    private readonly location: Location,
    private readonly titleService: Title,
    private readonly translate: TranslateService,
  ) {
    this.changeLangSubscriber = this.translate.onLangChange.subscribe({
      next: () => {
        this.changeLang.emit();
        this.setNewTittle();
      },
    });
  }


  /**
   * @description
   * Method implemented by the OnInit lifecycle
   */
  ngOnInit() {
    this.setNewTittle();
    this.themeService.onThemeChange().subscribe(
      {
        next: ({name}) => this.changeTheme.emit(MkadTableTheme[name]),
      },
    );
  }


  /**
   * @description
   * Method implemented by the OnDestroy lifecycle
   */
  ngOnDestroy() {
    this.changeLangSubscriber.unsubscribe();
  }

  /**
   * @description
   * Method that response to click on left button
   * @protected
   */
  protected handleClickGoBack() {
    this.location.back();
    this.clickGLeftButton.emit();
  }

  /**
   * @description
   * Method that sets the new browser tab title
   * @private
   */
  private setNewTittle(): void {
    const oneSecond = 1000;
    setTimeout(() => {
      this.titleService.setTitle(headerTitle(this.headerTitle));
    }, oneSecond);
  }

}
